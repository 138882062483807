<template>
    <ALERTUI
        ref="UI"
        :skin="skin"
        :dataModel="dataModel"
        :sliderData="sliderData"
        :showLoading="showLoading"
        :showSliderLoading="showSliderLoading"
        :errorCode="error.code"
        :settings="settings"
        :labels="initedLabels"
        :formatter="formatter"
        :unreadAlerts="unreadAlerts"
        :quoteSnapshot="quoteSnapshot"
        :alertConfig="alertConfig"
        @default-config-loaded="getAlertConfig"
        @alert-type-changed="changeAlertType"
        @sort-type-changed="changeSortField"
        @config-changed="saveAlertConfig"
        @alerts-deleted="deleteAlerts"
        @alert-created="subscribeAlert"
        @alert-updated="updateAlert"
        @page-changed="changePage"
        @slider-scrolled="getSliderTriggeredAlert"
        @alerts-acknowledged="acknowledgeAlerts"
        @settings-changed="changeSettings"
        @manage-clicked="manageAlert"
        @create-canceled="cancelCreate"
        @unread-alerts-count-changed="changeUnreadAlertsCount"
        @quote-symbol-changed="getQuoteSnapshot"
    >
    </ALERTUI>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import ALERTUI from './alert-ui';
import { hasChange, hasValue } from './metadata/alert-types';
import { quoteDataPoints } from './metadata/datapoint';
import {
    SETTINGS,
    NOTE_TYPES,
    NOTE_SOUND,
    DISPLAY_TIME,
    ALERT_TYPES
} from './common/';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-alert',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    components: {
        ALERTUI
    },
    data() {
        return {
            showLoading: false,
            showSliderLoading: false,
            defaultConfig: {
                settings: {
                    showHeader: false,
                    showSetting: false,
                    showBorder: false,
                    autoHeight: true,
                    updateLastAccessTime: true, //loop call will not extend session time, true is enable, false is disable;
                    languageId: 'en-US',
                    /**
                     *  notificationType, notificationSound, displayTime, triggeredTimeLimit
                     *  Above settings only works for new users, old users will get these settings from backend
                     */
                    notificationType: NOTE_TYPES.SCREEN, // screenOnly / emailOnly / screen&email
                    notificationSound: NOTE_SOUND.OFF, // on / off,
                    displayTime: DISPLAY_TIME.ONE_M, // 1 minute
                    triggeredTimeLimit: 30,
                    pullingFrequency: 30000, // get triggered alerts every 30 seconds
                    alertType: ALERT_TYPES.ACTIVE,
                    rowsPerPage: 10,
                    rowsPerPageList: [10, 25, 50],
                    showPageInfo: true,
                    showPageSelect: true,
                    dataType: 'pull', // pull or stream
                    sliderContainer: null,
                    skin: '',
                    frozenColumn: 0,
                    soundUrl: 'https://rtqimg.morningstar.com/media/alert.mp3',
                    settingsItem: Object.values(SETTINGS).join(','), // 'email,displayTime,notificationType,notificationSound,triggeredTimeLimit'
                    stickyLeadBreakPoints: [
                        {
                            stickyColIndices: [0, 1],
                            columnsPerPage: 2,
                            minWidth: 0,
                            maxWidth: 375
                        },
                        {
                            stickyColIndices: [0, 1],
                            columnsPerPage: 4,
                            minWidth: 375,
                            maxWidth: 800
                        },
                        {
                            stickyColIndices: [0, 1],
                            columnsPerPage: 6,
                            minWidth: 800,
                            maxWidth: 1000
                        }
                    ]
                },
                labels: labels,
                format: {
                    number: {
                        dataType: 'number',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    },
                    date: {
                        dataType: 'date',
                        day: '2-digit',
                        month: 'numeric',
                        year: 'numeric'
                    },
                    dateTime: {
                        dataType: 'date',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        day: '2-digit',
                        month: 'numeric',
                        year: 'numeric',
                        hour12: false
                    },
                    sliderDate: {
                        dataType: 'date',
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                    },
                    sliderTime: {
                        dataType: 'date',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    }
                },
                intlNamespace: 'mwc-markets-alert'
            },
            quoteSnapshot: {},
            dataModel: {
                total: 0,
                list: []
            },
            sliderData: {
                total: 0,
                start: 0,
                list: []
            },
            unreadAlerts: {
                count: null,
                latestAlert: null
            },
            error: {
                code: ''
            },
            autocomplete: {},
            alertConfig: {}
        };
    },
    computed: {
        initedLabels() {
            return this.mergeLabels(labels);
        }
    },
    watch: {
        'unreadAlerts.count'(value, oldValue) {
            if (value && oldValue !== undefined && oldValue !== null) {
                this.$emit('unread-count-changed', value);
            }
        }
    },
    created() {
        this.alertType = null;
        this.namespace = utils.namespace('alert');
        this.initialized = false;
        this.start = 0;
        this.count = 0;
        this.tickers = [];
        this.sliderTickers = [];
        this.totalCount = {
            active: 0,
            triggered: 0,
            recurring: 0
        };
        this.pullSubscribePromise = null;
        this.streamSubscribePromise = null;
        this.currentDataType = null;
        this.initData(this.settings.dataType);
    },
    mounted() {
        utils.visibilityObserver.observe(
            this.$el,
            this._visibilityObserverCallback
        );
    },
    beforeDestroy() {
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
        if (this.isSubscribing) {
            this.isSubscribing = false;
            this.unsubscribe(this.tickers);
        }
    },

    methods: {
        initData() {
            this.changeDataType(this.settings.dataType);
        },
        _visibilityObserverCallback({ visibility }) {
            if (visibility) {
                if (!this.initialized) {
                    this.initialized = true;
                    this.getAlerts();
                } else if (this.tickers) {
                    this.subscribe(this.tickers);
                }
            } else {
                if (this.isSubscribing) {
                    this.unsubscribe(this.tickers);
                    this.isSubscribing = false;
                }
            }
        },
        exposeMethods() {
            // keep this if you want to expose the method to custom element
            return [
                'toggleSettings',
                'toggleSliderAlerts',
                'createAlert',
                'refresh'
            ];
        },
        changeDataType(value) {
            if (!this.mktdata) {
                return;
            }
            if (this[`${this.currentDataType}SubscribePromise`]) {
                if (this.tickers) {
                    this.unsubscribe(this.tickers);
                }
            }
            this.currentDataType = value === 'stream' ? value : 'pull';
            if (!this[`${this.currentDataType}SubscribePromise`]) {
                this[`${this.currentDataType}SubscribePromise`] = this.mktdata[
                    this.currentDataType
                ]();
            }
            if (this.tickers) {
                this.subscribe(this.tickers);
            }
        },
        getParams() {
            const params = {
                count: this.count,
                start: this.start
            };
            if (this.sortField) {
                params.orderField = this.sortField;
                params.order = this.sortAsc ? 'asc' : 'desc';
            }
            if (this.alertType === ALERT_TYPES.TRIGGERED) {
                params.startDate = this._getStartDate();
            }
            return params;
        },
        getMethod() {
            let method = '';
            if (this.alertType === ALERT_TYPES.ACTIVE) {
                method = 'activeAlerts';
            } else if (this.alertType === ALERT_TYPES.TRIGGERED) {
                method = 'triggeredAlerts';
            } else if (this.alertType === ALERT_TYPES.RECURRING) {
                method = 'recurringAlerts';
            }
            return method;
        },
        getAlerts() {
            if (this.mktdata && this.alertType) {
                this.showLoading = true;
                this.dataModel = utils.extend({}, this.dataModel, {
                    list: [],
                    total: 0
                });
                if (this.getAlertPromise) {
                    this.getAlertPromise.cancel();
                }
                this.getAlertPromise = utils.cancelablePromise(
                    this.mktdata[this.getMethod()](this.getParams())
                );
                this.getAlertPromise.promise
                    .then(res => {
                        if (
                            res.status.code === '0' &&
                            res.data &&
                            res.data.total
                        ) {
                            this.setDataModel(res.data);
                        } else {
                            this.showLoading = false;
                            this.renderCompleted = true;
                        }
                    })
                    .catch(({ isCanceled }) => {
                        if (!isCanceled) {
                            this.showLoading = false;
                        }
                    });
                // need to get unread alerts every time when fetch new alert
                this.getUnreadAlerts();
            }
        },
        updateAlert(data) {
            for (const key in data) {
                if (typeof data[key] === 'undefined' || data[key] === null) {
                    delete data[key];
                }
            }
            this.error.code = '';
            this.mktdata
                .updateAlert(data)
                .then(data => {
                    if (data.status.code === '0') {
                        this.$refs.UI.updateAlertSuccess();
                    } else {
                        this.error.code = this._getErrorCode(data.status.code);
                    }
                })
                .catch(e => {
                    this.error.code = this._getErrorCode();
                });
        },
        subscribeAlert(data) {
            for (const key in data) {
                if (!data[key]) {
                    delete data[key];
                }
            }
            this.error.code = '';
            this.mktdata
                .subscribeAlert(data)
                .then(data => {
                    if (data.status.code === '0') {
                        this.start = 0;
                        this.dataModel.count++;
                        this.$refs.UI.subscribeAlertSuccess();
                        this.$emit('subscribe-succeeded');
                        this.getUnreadAlerts();
                    } else {
                        this.error.code = this._getErrorCode(data.status.code);
                    }
                })
                .catch(e => {
                    this.error.code = this._getErrorCode();
                });
        },
        deleteAlerts(alerts) {
            if (alerts && alerts !== '') {
                const params = {
                    uniqueAlertID: alerts
                };
                if (this.alertType === ALERT_TYPES.TRIGGERED) {
                    params.triggeredAlert = true;
                }
                this.mktdata
                    .deleteAlert(params)
                    .then(res => {
                        let errorCode = '0';
                        if (res.status.code === '0') {
                            let list = this.dataModel.list;
                            list = list.filter(
                                item => alerts.indexOf(item.id) < 0
                            );
                            this.$set(this.dataModel, 'list', list);
                        } else {
                            errorCode = '-1';
                        }
                        this.$refs.UI.deleteAlertsFinished({
                            errorCode
                        });
                    })
                    .catch(e => {
                        this.$refs.UI.deleteAlertsFinished({
                            errorCode: '-1'
                        });
                    });
            }
        },
        getQuoteSnapshot(instrument) {
            if (this.snapshotPromise) {
                this.snapshotPromise.cancel();
            }
            this.snapshotPromise = utils.cancelablePromise(
                this.mktdata.quotes({
                    securities: [instrument]
                })
            );
            this.snapshotPromise.promise
                .then(data => {
                    const map = {};
                    if (data && data.length) {
                        const quoteData = data[0].quotes;
                        const dataFilds = quoteDataPoints.map(dp => {
                            return dp.id;
                        });
                        quoteData.forEach(dp => {
                            if (utils.inArray(dataFilds, dp.name) > -1) {
                                map[dp.name] = dp.value;
                            }
                        });
                        this.quoteSnapshot = utils.extend(
                            true,
                            {},
                            {
                                instrument: data[0].security,
                                quotes: map
                            }
                        );
                    }

                    this.snapshotPromise = null;
                })
                .catch(() => {
                    this.snapshotPromise = null;
                    this.quoteSnapshot = {};
                });
        },
        changeAlertType(type, count, start, triggeredTimeLimit) {
            this.alertType = type;
            this.count = count;
            this.start = start;
            this.dataModel.total = 0;
            this.triggeredTimeLimit = triggeredTimeLimit;
            this.sortField = null;
            this.sortAsc = null;
            if (this.initialized) {
                this.getAlerts();
            }
            if (!this.interval) {
                this.pullUnreadAlerts(this.settings.pullingFrequency);
            }
        },
        changeSortField({ sortField, sortAsc }) {
            this.sortField = sortField;
            this.sortAsc = sortAsc;
            this.start = 0;
            this.dataModel.total = 0;
            if (this.initialized) {
                this.getAlerts();
            }
        },
        changePage({
            start,
            count,
            sortField = null,
            sortAsc = null,
            triggeredTimeLimit = null
        }) {
            this.start = start;
            this.count = count;
            this.sortField = sortField;
            this.sortAsc = sortAsc;
            this.triggeredTimeLimit = triggeredTimeLimit;
            if (this.initialized) {
                this.getAlerts();
            }
        },
        getSliderTriggeredAlert(start, count, triggeredTimeLimit) {
            this.triggeredTimeLimit = triggeredTimeLimit;
            const params = {
                start,
                count
            };

            params.startDate = this._getStartDate();
            this.showSliderLoading = true;
            this.mktdata
                .triggeredAlerts(params)
                .then(res => {
                    if (res.status.code === '0') {
                        const data = res.data;
                        this.sliderData.total = data.total;
                        this.sliderData.start = data.start;
                        if (!data.list.length) {
                            this.sliderData.list = [];
                            this.showSliderLoading = false;
                            return;
                        }
                        data.list = data.list.map(item => {
                            return this._convertAlertChangeAndValue(item);
                        });
                        this.sliderTickers = [
                            ...this.sliderTickers,
                            ...data.list.map(d => {
                                return d.rtTicker;
                            })
                        ];
                        this.sliderTickers = Array.from(
                            new Set(this.sliderTickers)
                        );
                        let list = data.list;
                        if (start > 0) {
                            list = [...this.sliderData.list, ...list];
                        }
                        if (!this.sliderTickers.length) {
                            this.showSliderLoading = false;
                            return;
                        }
                        this.mktdata
                            .securities(this.sliderTickers)
                            .then(securities => {
                                list = this._mergeSecurityData(
                                    list,
                                    securities
                                );
                                this.$set(this.sliderData, 'list', list);
                                this.showSliderLoading = false;
                            })
                            .catch(e => {
                                this.showSliderLoading = false;
                            });
                    }
                })
                .catch(() => {
                    this.showSliderLoading = false;
                });
        },
        acknowledgeAlerts(uniqueAlertID, resolve) {
            let list = this.sliderData.list;
            if (uniqueAlertID && uniqueAlertID !== 'all') {
                const target = utils.find(list, item => {
                    return item.uniqueAlertID === uniqueAlertID;
                });
                if (target) {
                    target.readStatus = true;
                }
            } else if (uniqueAlertID === 'all') {
                list = list.map(item => {
                    item.readStatus = true;
                    return item;
                });
            }
            this.$set(this.sliderData, 'list', list);
            this.mktdata
                .acknowledgeAlerts({
                    uniqueAlertID
                })
                .then(() => {
                    if (uniqueAlertID !== 'all') {
                        this.getUnreadAlerts();
                    } else {
                        this.unreadAlerts.count = 0;
                    }
                    if (typeof resolve === 'function') {
                        resolve();
                    }
                })
                .catch(e => {});
        },
        pullUnreadAlerts(frequency) {
            if (!this.mktdata) {
                return;
            }
            this.getUnreadAlerts(false);
            if (frequency > 0) {
                this.interval = window.setInterval(() => {
                    this.getUnreadAlerts();
                }, frequency);
            }
        },
        async getUnreadAlerts(needSecurityData = true) {
            const params = {
                readStatus: false,
                startDate: this._getStartDate(),
                start: 0,
                count: 1
            };
            params.updateLastAccessTime = this.settings.updateLastAccessTime;
            const res = await this.mktdata.triggeredAlerts(params).catch(e => {
                return {};
            });
            const data = res.data;
            if (res.status && res.status.code === '0' && res.data) {
                if (data.list.length) {
                    this.unreadAlerts.startDate = data.list[0].triggeredTime;
                    const ticker = data.list[0].rtTicker;
                    let list = data.list;
                    if (
                        needSecurityData &&
                        this.unreadAlerts.count !== data.total
                    ) {
                        const securities = await this.mktdata
                            .securities(ticker)
                            .catch(e => []);
                        list = this._mergeSecurityData(data.list, securities);
                    }
                    this.unreadAlerts.latestAlert = this._convertAlertChangeAndValue(
                        list[0]
                    );
                    this.unreadAlerts.count = data.total;
                } else {
                    this.unreadAlerts.count = 0;
                }
            }
        },
        saveAlertConfig(config) {
            this.mktdata.saveAlertConfig(config).catch(e => {});
        },
        getAlertConfig(defaultConfig) {
            this.mktdata
                .getAlertConfig(defaultConfig)
                .then(res => {
                    if (res && res.data) {
                        this.alertConfig = res.data;
                    }
                })
                .catch(e => {});
        },
        refresh() {
            this.$refs.UI.refresh();
        },
        _formatDate(date) {
            return `${date.getFullYear()}-${utils.prefixZero(
                date.getMonth() + 1
            )}-${utils.prefixZero(date.getDate())}`;
        },
        _getStartDate() {
            const _now = new Date();
            _now.setDate(_now.getDate() - this.triggeredTimeLimit);
            return this._formatDate(_now);
        },
        subscribe(tickers) {
            if (tickers && tickers.length) {
                this.isSubscribing = true;
            }
            this[`${this.currentDataType}SubscribePromise`].then(subscriber => {
                subscriber.subscribe(
                    tickers,
                    {
                        data: (security, updates) => {
                            const dataFields = this.fieldsNeedUpdate(updates);
                            if (dataFields.length) {
                                let list = this.dataModel.list;
                                list = this._mergeMarketData(
                                    list,
                                    dataFields,
                                    security
                                );
                                this.$set(this.dataModel, 'list', list);
                            }
                        },
                        error: e => {}
                    },
                    {
                        updateLastAccessTime: this.settings.updateLastAccessTime
                    }
                );
            });
        },
        unsubscribe(tickers) {
            this[`${this.currentDataType}SubscribePromise`]
                .then(subscriber => {
                    subscriber.unsubscribe(tickers);
                })
                .catch(e => {});
        },
        fieldsNeedUpdate(dataFields) {
            return dataFields.filter(item => {
                return item.name === 'lastPrice' || item.name === 'tradeDate';
            });
        },
        setDataModel(data) {
            this.dataModel.total = data.total;
            this.totalCount[this.alertType] = data.total;
            if (!data.list.length) {
                return;
            }
            this.unsubscribe(this.tickers);
            this.tickers = this._getTickers(data.list);
            let list = data.list.map(d => {
                d.id = d.uniqueAlertID;
                d = this._convertAlertChangeAndValue(d);
                return d;
            });
            if (this.securityPromise) {
                this.securityPromise.cancel();
            }
            this.securityPromise = utils.cancelablePromise(
                this.mktdata.securities(this.tickers)
            );

            this.securityPromise.promise
                .then(securities => {
                    list = this._mergeSecurityData(list, securities);
                    // convert 14.1.IBM to 126.1.IBM
                    this.tickers = this._getTickers(list);
                    this.mktdata
                        .quotes({
                            securities: this.tickers
                        })
                        .then(quoteData => {
                            quoteData.forEach(qd => {
                                const dataFields = this.fieldsNeedUpdate(
                                    qd.quotes
                                );
                                if (dataFields.length) {
                                    list = this._mergeMarketData(
                                        list,
                                        qd.quotes,
                                        qd.security
                                    );
                                }
                            });
                            this.$set(this.dataModel, 'list', list);
                            this.showLoading = false;
                            this.renderCompleted = true;
                        })
                        .catch(e => {
                            this.$set(this.dataModel, 'list', list);
                            this.showLoading = false;
                            this.renderCompleted = true;
                        });
                    this.subscribe(this.tickers);
                })
                .catch(({ isCanceled }) => {
                    this.showLoading = false;
                });
        },
        _getTickers(list) {
            return Array.from(
                new Set(
                    list.map(d => {
                        return d.rtTicker;
                    })
                )
            );
        },
        _mergeSecurityData(list, securities) {
            list.forEach(item => {
                securities.forEach(security => {
                    if (item.rtTicker === security.queryKey) {
                        item.currency = security.currency;
                        item.name = security.name;
                        item.symbol = security.rtSymbol;
                        item.rtTicker = security.quoteInstrument;
                    }
                });
            });
            return list;
        },
        _mergeMarketData(list, marketData, security) {
            list.forEach(item => {
                if (item.rtTicker === security) {
                    marketData.forEach(d => {
                        if (d.name !== 'name') {
                            item[d.name] = d.value;
                        }
                    });
                }
            });
            return list;
        },
        _convertAlertChangeAndValue(alert) {
            if (!alert.change && alert.targetValue) {
                const _id = utils.prefixZero(alert.alertID);
                if (hasChange(_id) && !hasValue(_id)) {
                    alert.change = alert.targetValue;
                    alert.targetValue = null;
                }
            }
            return alert;
        },
        _getErrorCode(code) {
            let errorCode = '';
            switch (code) {
                case '10004':
                    errorCode = 'error_duplicate';
                    break;
                case '10005':
                    errorCode = 'error_triggered';
                    break;
                default:
                    errorCode = 'error_subscribe';
            }
            return errorCode;
        },
        toggleSliderAlerts(e) {
            if (this.$refs.UI && this.$refs.UI.toggleSliderAlerts) {
                this.$refs.UI.toggleSliderAlerts(e);
            }
        },
        createAlert(security) {
            if (this.$refs.UI && this.$refs.UI.createAlert) {
                this.$refs.UI.createAlert(security);
            }
        },
        changeUnreadAlertsCount(e) {
            this.$emit('unread-alerts-count-changed', e);
        },
        manageAlert() {
            this.$emit('manage-clicked');
        },
        cancelCreate() {
            this.$emit('create-canceled');
        },
        _setError(error) {
            this.error = error;
        }
    }
};
</script>
