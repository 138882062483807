<template>
    <section
        :class="cls"
        tabindex="0"
        @keyup.enter="acknowledgeAlerts(alert.uniqueAlertID)"
    >
        <div :class="namespace('content')">
            <span :class="namespace('__symbol')"
                >{{ alert.name }} | {{ alert.symbol }}
            </span>
            <span :class="namespace('__message')" v-html="alert.message"></span>
        </div>
        <div :class="namespace('__time')">{{ alert.triggeredTime }}</div>
        <div
            :class="namespace('mark-as-read-icon')"
            v-if="!alert.readStatus"
            :title="labels.markAsRead"
            :arial-label="labels.markAsRead"
            tabindex="0"
            role="button"
            @click.stop="acknowledgeAlerts(alert.uniqueAlertID)"
            @keyup.enter="acknowledgeAlerts(alert.uniqueAlertID)"
        ></div>
    </section>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
import {
    hasChange,
    isPureChange,
    hasValue,
    isVolumeRelated
} from '../metadata/alert-types';
export default {
    name: 'mwc-markets-alert-block',
    props: {
        dataModel: {
            type: Object,
            default() {
                return {};
            }
        },
        labels: {
            type: Object,
            default() {
                return {};
            }
        },
        formatter: {
            type: Object,
            default() {
                return {};
            }
        },
        classes: {
            type: Array,
            default() {
                return [];
            }
        },
        skin: {
            type: String,
            default: 'default'
        },
        container: {
            type: HTMLElement
        }
    },
    computed: {
        cls() {
            return [
                this.namespace(),
                this.alert.cls,
                ...this.classes,
                `${this.namespace()}--${utils.getSkinSuffix(this.skin)}`
            ];
        },
        alert() {
            if (utils.isEmptyObject(this.dataModel)) {
                return {};
            }
            const {
                symbol,
                name,
                readStatus,
                uniqueAlertID,
                localTimeZone
            } = this.dataModel;
            const alertID = utils.prefixZero(this.dataModel.alertID);
            const localTime =
                this.dataModel.localTriggeredTime || '2019-07-19 13:38:25';
            let targetValue = '',
                triggeredValue = '',
                triggeredTime = localTime.replace(' ', 'T'),
                change = '';
            const time = utils.getFormatValue({
                dataType: 'sliderTime',
                formatter: this.formatter,
                value: new Date(triggeredTime)
            });
            const date = utils.getFormatValue({
                dataType: 'sliderDate',
                formatter: this.formatter,
                value: new Date(triggeredTime)
            });
            const _isVolumeRelated = isVolumeRelated({
                id: this.dataModel.alertID
            });
            triggeredTime = `${time} ${date} ${localTimeZone || ''}`;
            if (hasValue(alertID)) {
                targetValue = utils.getFormatValue({
                    dataType: 'number',
                    formatter: this.formatter,
                    value: this.dataModel.targetValue
                });
                if (!_isVolumeRelated) {
                    targetValue = utils.getPriceWithCurrency(
                        targetValue,
                        this.dataModel.currency
                    );
                }
                targetValue = `<span class="${this.namespace(
                    'detail__value'
                )}">${targetValue}</span>`;
            }
            if (hasChange(alertID)) {
                if (isPureChange(alertID)) {
                    change = this.dataModel.change;
                } else {
                    change = +this.dataModel.change + 100;
                }
                change =
                    utils.getFormatValue({
                        dataType: 'number',
                        formatter: this.formatter,
                        value: change
                    }) + '%';
                change = `<span class="${this.namespace(
                    'detail__change'
                )}">${change}</span>`;
            }
            if (this.dataModel.triggeredValue && !_isVolumeRelated) {
                triggeredValue = utils.getFormatValue({
                    dataType: 'number',
                    formatter: this.formatter,
                    value: this.dataModel.triggeredValue
                });
                triggeredValue = utils.getPriceWithCurrency(
                    triggeredValue,
                    this.dataModel.currency
                );
            }
            const message = utils.stringFormat(
                this.labels[`details_${alertID}`],
                {
                    change,
                    targetValue,
                    triggeredValue
                }
            );
            return {
                symbol,
                name,
                message,
                triggeredTime,
                readStatus,
                uniqueAlertID,
                cls: readStatus
                    ? this.namespace('read')
                    : this.namespace('unread')
            };
        }
    },
    created() {
        this.namespace = utils.namespace('alert-block');
    },
    mounted() {
        if (this.container instanceof HTMLElement) {
            this.container.appendChild(this.$el);
        }
    },
    methods: {
        acknowledgeAlerts(uniqueAlertID) {
            this.$emit('acknowledge-alerts', uniqueAlertID);
        }
    }
};
</script>
<style lang="scss">
$namespace: 'mwc-markets-alert-block';
@import '@mds/constants';
@import '@mds/typography';

.#{$namespace} {
    padding: 10px;
    font-size: $mds-typography-font-size-s;
    position: relative;
    border-bottom: solid $mds-color-neutral-80 1px;
    font-family: $mds-typography-font-family-sans-serif;
    background: $mds-background-color-white;
    &:focus {
        outline: none;
        box-shadow: $mds-box-shadow-focus;
        margin: 4px;
        .#{$namespace}-content {
            margin-top: -4px;
        }
        & > div {
            margin-left: -4px;
        }
    }
    &-content {
        padding: 2px 20px 2px 2px;
    }
    &__symbol,
    &__value,
    &__change {
        font-weight: bold;
    }
    &__message {
        margin-left: 5px;
    }
    &__time {
        padding: 10px 2px;
    }
    &-mark-as-read-icon {
        font-weight: 300;
        cursor: pointer;
        display: block;
        position: absolute;
        right: 12px;
        top: 13px;
        -webkit-transition: background-color 0.2s ease, border-color 0.2s ease;
        transition: background-color 0.2s ease, border-color 0.2s ease;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        border: 1px solid #ababab;
        &:focus {
            outline: none;
            box-shadow: $mds-box-shadow-focus;
        }
    }
    &-unread {
        background: $mds-color-neutral-95;
    }
}
.#{$namespace}.#{$namespace}--dark-gray {
    background: $mds-background-color-dark-gray;
    color: $mds-text-color-primary-on-dark;
    border-bottom-color: $mds-color-neutral-20;
    &.#{$namespace} {
        &-read {
            &:hover {
                background-color: $mds-color-neutral-37;
            }
        }
        &-unread {
            background-color: $mds-color-neutral-20;
            &:hover {
                background-color: $mds-color-neutral-37;
            }
        }
    }
}
</style>
