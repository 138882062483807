export const SETTINGS = {
    EMAIL: 'email',
    DISPLAY_TIME: 'displayTime',
    TRIGGERED_TIME: 'triggeredTimeLimit',
    NOTE_TYPE: 'notificationType',
    NOTE_SOUND: 'notificationSound'
};

export const NOTE_TYPES = {
    SCREEN: 'screenOnly',
    EMAIL: 'emailOnly',
    SCREEN_EMAIL: 'screen&email'
};

export const NOTE_SOUND = {
    ON: 'on',
    OFF: 'off'
};

export const DISPLAY_TIME = {
    NONE: 'none',
    FIVE_S: '5 seconds',
    TEN_S: '10 seconds',
    THIRTY_S: '30 seconds',
    ONE_M: '1 minute',
    THREE_M: '3 minutes',
    FIVE_M: '5 minutes',
    TEN_M: '10 minutes',
    FIFTEEN_M: '15 minutes',
    THIRTY_M: '30 minutes',
    ONE_H: '1 hour'
};

export const ALERT_TYPES = {
    ACTIVE: 'active',
    RECURRING: 'recurring',
    TRIGGERED: 'triggered'
};
