(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mwc-markets-core"), require("vue-content-loader"));
	else if(typeof define === 'function' && define.amd)
		define(["mwc-markets-core", "vue-content-loader"], factory);
	else if(typeof exports === 'object')
		exports["mwcMarketsAlert"] = factory(require("mwc-markets-core"), require("vue-content-loader"));
	else
		root["mwcMarketsAlert"] = factory(root["mwcMarketsCore"], root["contentLoaders"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__123__) {
return 