import api from "!../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../../../node_modules/sass-loader/dist/cjs.js??ref--9-oneOf-1-3!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./alert-slider.vue?vue&type=style&index=0&lang=scss&";

var options = {"injectType":"singletonStyleTag","attributes":{"context":"mwc-markets-alert"}};

options.insert = "head";
options.singleton = true;

var update = api(content, options);



export default content.locals || {};