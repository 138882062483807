var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.dataModel, function(item) {
      return _c(
        "content-loader",
        {
          key: item.key,
          attrs: {
            height: _vm.height,
            width: _vm.width,
            speed: _vm.speed,
            primaryColor: _vm.primaryColor,
            secondaryColor: _vm.secondaryColor
          }
        },
        [
          _c("rect", {
            attrs: {
              x: "12",
              y: "10",
              rx: "0",
              ry: "0",
              width: "210",
              height: "12"
            }
          }),
          _vm._v(" "),
          _c("rect", {
            attrs: {
              x: "12",
              y: "30",
              rx: "0",
              ry: "0",
              width: "200",
              height: "10"
            }
          }),
          _vm._v(" "),
          _c("rect", {
            attrs: {
              x: "12",
              y: "45",
              rx: "0",
              ry: "0",
              width: "180",
              height: "8"
            }
          }),
          _vm._v(" "),
          _c("rect", {
            attrs: {
              x: "12",
              y: "62",
              rx: "0",
              ry: "0",
              width: "110",
              height: "14"
            }
          })
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }