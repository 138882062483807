var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.namespace(),
      attrs: {
        autoHeight: _vm.watchedSettings.autoHeight,
        errorMessage: _vm.containerErrorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading,
        skin: _vm.watchedSettings.skin
      },
      on: { "setting-click": _vm.toggleSettings }
    },
    [
      _c(
        "markets-ui-settings",
        {
          ref: "settings",
          class: _vm.namespace("settings"),
          attrs: {
            slot: "settings",
            "triggered-by": _vm.settingsPopoverTriggeredId,
            visible: _vm.settingsPopoverVisible,
            skin: _vm.watchedSettings.skin,
            showBack: !!_vm.settingsPopoverNavSelected,
            title: _vm.settingsTitle,
            "show-done": !_vm.settingsPopoverNavSelected,
            "done-label": _vm.initedLabels.save,
            "done-disable": !!_vm.emailError
          },
          on: {
            done: _vm.saveAlertConfig,
            back: _vm.changeSettingsNav,
            hide: _vm.hideSettingsPopover
          },
          slot: "settings"
        },
        [
          _c(
            "markets-ui-form",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showEmail
                ? _c("markets-ui-input", {
                    class: _vm.namespace("settings__email"),
                    attrs: {
                      error: !!_vm.emailError,
                      "error-text": [_vm.emailError],
                      label: _vm.initedLabels.emailLabel,
                      skin: _vm.watchedSettings.skin,
                      autocomplete: "email"
                    },
                    on: {
                      blur: _vm.validEmail,
                      click: function($event) {
                        return _vm.emailClick($event)
                      }
                    },
                    model: {
                      value: _vm.alertSettings.email,
                      callback: function($$v) {
                        _vm.$set(_vm.alertSettings, "email", $$v)
                      },
                      expression: "alertSettings.email"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showDisplayTime
                ? _c("markets-ui-select", {
                    class: _vm.namespace("settings__display-time"),
                    attrs: {
                      size: "small",
                      label: _vm.initedLabels.displayTime,
                      skin: _vm.watchedSettings.skin,
                      "data-model": _vm.displayTimeDataModel
                    },
                    on: { change: _vm.changeDisplayTime }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showTriggeredTimeLimit
                ? _c(
                    "div",
                    { class: _vm.namespace("settings__triggered-time") },
                    [
                      _c("markets-ui-input", {
                        ref: "triggerTimeLimit",
                        class: _vm.namespace("settings__number-input"),
                        attrs: {
                          min: _vm.triggeredTimeLimit.min,
                          max: _vm.triggeredTimeLimit.max,
                          skin: _vm.watchedSettings.skin,
                          type: "number",
                          label: _vm.initedLabels.triggeredTimeLimit
                        },
                        on: {
                          keydown: function($event) {
                            return _vm.onTimeLimitKeydown($event)
                          },
                          change: function($event) {
                            return _vm.onTimeLimitChange(
                              _vm.alertSettings.triggeredTimeLimit
                            )
                          }
                        },
                        model: {
                          value: _vm.alertSettings.triggeredTimeLimit,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.alertSettings,
                              "triggeredTimeLimit",
                              $$v
                            )
                          },
                          expression: "alertSettings.triggeredTimeLimit"
                        }
                      }),
                      _c("span", [_vm._v(" " + _vm._s(_vm.initedLabels.days))])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.settingsPopoverNavSelected
                ? _c("markets-ui-settings-navigation", {
                    attrs: {
                      skin: _vm.watchedSettings.skin,
                      dataModel: _vm.settingsPopoverNav
                    },
                    on: { change: _vm.changeSettingsNav }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !!_vm.settingsPopoverNavSelected
            ? _c("markets-ui-list", {
                attrs: {
                  slot: "content",
                  skin: _vm.watchedSettings.skin,
                  variation: "selection",
                  size: "small",
                  dataModel: _vm.settingsPopoverNavSelectedList
                },
                on: { change: _vm.changeSettingsNavSelected },
                slot: "content"
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.containerErrorMessage
        ? _c(
            "div",
            {
              class: _vm.namespace("body"),
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _c("markets-ui-menus", {
                attrs: {
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.menuDataModel
                },
                on: { change: _vm.changeAlertType }
              }),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.namespace("buttons") },
                [
                  _c("markets-ui-button", {
                    ref: "settingsButton",
                    class: _vm.namespace("button__trash"),
                    attrs: {
                      variation: "icon-only",
                      icon: "trash",
                      size: "medium",
                      skin: _vm.watchedSettings.skin,
                      disabled: !_vm.selectRowIds.length,
                      "aria-pressed": "trash" === _vm.activeIcon,
                      text: _vm.initedLabels.delete
                    },
                    on: { click: _vm.deleteClicked }
                  }),
                  _vm._v(" "),
                  _c("markets-ui-button", {
                    ref: "settingsButton",
                    class: _vm.namespace("button__refresh"),
                    attrs: {
                      variation: "icon-only",
                      icon: "refresh",
                      size: "medium",
                      skin: _vm.watchedSettings.skin,
                      "aria-pressed": "refresh" === _vm.activeIcon,
                      text: _vm.initedLabels.refresh
                    },
                    on: { click: _vm.refresh }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { class: _vm.namespace("create-button-wrapper") },
                    [
                      _c("markets-ui-button", {
                        ref: "settingsButton",
                        class: _vm.namespace("button__create"),
                        attrs: {
                          skin: _vm.watchedSettings.skin,
                          text: _vm.initedLabels.createAlert
                        },
                        on: {
                          click: function($event) {
                            return _vm.createAlert()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                class: _vm.namespace("accessibility-hidden"),
                attrs: { "aria-live": "polite" },
                domProps: { textContent: _vm._s(_vm.loadingStatus) }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: _vm.namespace("grid-container"),
                  attrs: { "aria-busy": _vm.showLoading }
                },
                [
                  _c("markets-ui-grid", {
                    ref: "grid",
                    attrs: {
                      skin: _vm.watchedSettings.skin,
                      formatter: _vm.formatter,
                      option: _vm.gridOption,
                      columns: _vm.gridColumns,
                      rows: _vm.gridRows,
                      caption: _vm.gridCaption,
                      labels: _vm.initedLabels,
                      "select-row-ids": _vm.selectRowIds
                    },
                    on: {
                      sort: _vm.onSort,
                      "icon-click": _vm.modifyAlert,
                      "page-changed": _vm.gotoPage,
                      "rows-selected-changed": _vm.selectedChanged
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("AlertDialog", {
                attrs: {
                  options: _vm.dialogOptions,
                  labels: _vm.initedLabels,
                  dataModel: _vm.alertDataModel,
                  errorMessage: _vm.errorMessage,
                  skin: _vm.watchedSettings.skin,
                  quoteSnapshot: _vm.formatedQuoteData
                },
                on: {
                  "quote-symbol-changed": _vm.getQuoteSnapshot,
                  responsed: _vm.dialogResponse
                }
              }),
              _vm._v(" "),
              _c("alert-slider", {
                ref: "alertSlider",
                attrs: {
                  labels: _vm.initedLabels,
                  visible: _vm.slider.visible,
                  dismissed: _vm.slider.dismissed,
                  dataModel: _vm.sliderData,
                  formatter: _vm.formatter,
                  skin: _vm.watchedSettings.skin,
                  showLoading: _vm.showSliderLoading
                },
                on: {
                  "slider-scrolled": _vm.getSliderAlerts,
                  "toggle-settings": _vm.toggleSettings,
                  hide: _vm.hideSlider,
                  "manage-clicked": _vm.manageAlert,
                  "alerts-acknowledged": _vm.acknowledgeAlerts
                }
              }),
              _vm._v(" "),
              _c("alert-block", {
                ref: "screenAlert",
                class: _vm.screenAlertCls,
                attrs: {
                  formatter: _vm.formatter,
                  labels: _vm.initedLabels,
                  "data-model": _vm.screenAlert.latestAlert,
                  skin: _vm.watchedSettings.skin
                },
                on: { "acknowledge-alerts": _vm.acknowledgeScreenAlert }
              }),
              _vm._v(" "),
              _c("audio", { class: _vm.namespace("audio") }),
              _vm._v(" "),
              _c(
                "markets-ui-dialog",
                {
                  attrs: {
                    slot: "supplemental-content",
                    skin: _vm.watchedSettings.skin,
                    visible: _vm.dialog.visible,
                    message: _vm.dialog.message,
                    resolve: _vm.dialog.resolve,
                    cancel: _vm.dialog.cancel,
                    "action-required": ""
                  },
                  on: { click: _vm.dialogClicked },
                  slot: "supplemental-content"
                },
                [
                  !!_vm.dialog.errorMsg
                    ? _c(
                        "div",
                        {
                          class: _vm.moduleClassName(
                            "mds-input__field-error-wrapper"
                          )
                        },
                        [
                          _c(
                            "span",
                            {
                              class: [
                                _vm.moduleClassName("mds-field-error"),
                                _vm.namespace("error-message")
                              ]
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.dialog.errorMsg) +
                                  "\n                "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }