<template>
    <markets-ui-container
        :class="namespace()"
        :autoHeight="watchedSettings.autoHeight"
        :errorMessage="containerErrorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        :skin="watchedSettings.skin"
        @setting-click="toggleSettings"
    >
        <markets-ui-settings
            slot="settings"
            :triggered-by="settingsPopoverTriggeredId"
            :visible="settingsPopoverVisible"
            :skin="watchedSettings.skin"
            :showBack="!!settingsPopoverNavSelected"
            :class="namespace('settings')"
            :title="settingsTitle"
            :show-done="!settingsPopoverNavSelected"
            :done-label="initedLabels.save"
            :done-disable="!!emailError"
            ref="settings"
            @done="saveAlertConfig"
            @back="changeSettingsNav"
            @hide="hideSettingsPopover"
        >
            <markets-ui-form slot="content">
                <markets-ui-input
                    v-if="showEmail"
                    :class="namespace('settings__email')"
                    :error="!!emailError"
                    :error-text="[emailError]"
                    :label="initedLabels.emailLabel"
                    :skin="watchedSettings.skin"
                    autocomplete="email"
                    v-model="alertSettings.email"
                    @blur="validEmail"
                    @click="emailClick($event)"
                />

                <markets-ui-select
                    v-if="showDisplayTime"
                    :class="namespace('settings__display-time')"
                    size="small"
                    :label="initedLabels.displayTime"
                    :skin="watchedSettings.skin"
                    :data-model="displayTimeDataModel"
                    @change="changeDisplayTime"
                />
                <div
                    :class="namespace('settings__triggered-time')"
                    v-if="showTriggeredTimeLimit"
                >
                    <markets-ui-input
                        v-model="alertSettings.triggeredTimeLimit"
                        :class="namespace('settings__number-input')"
                        :min="triggeredTimeLimit.min"
                        :max="triggeredTimeLimit.max"
                        :skin="watchedSettings.skin"
                        ref="triggerTimeLimit"
                        type="number"
                        :label="initedLabels.triggeredTimeLimit"
                        @keydown="onTimeLimitKeydown($event)"
                        @change="
                            onTimeLimitChange(alertSettings.triggeredTimeLimit)
                        "
                    /><span> {{ initedLabels.days }}</span>
                </div>

                <markets-ui-settings-navigation
                    v-if="!settingsPopoverNavSelected"
                    :skin="watchedSettings.skin"
                    :dataModel="settingsPopoverNav"
                    @change="changeSettingsNav"
                >
                </markets-ui-settings-navigation>
            </markets-ui-form>
            <markets-ui-list
                slot="content"
                v-if="!!settingsPopoverNavSelected"
                :skin="watchedSettings.skin"
                variation="selection"
                size="small"
                :dataModel="settingsPopoverNavSelectedList"
                @change="changeSettingsNavSelected"
            />
        </markets-ui-settings>
        <div
            slot="content"
            :class="namespace('body')"
            v-if="!containerErrorMessage"
        >
            <markets-ui-menus
                :skin="watchedSettings.skin"
                :dataModel="menuDataModel"
                @change="changeAlertType"
            >
            </markets-ui-menus>
            <div :class="namespace('buttons')">
                <markets-ui-button
                    variation="icon-only"
                    icon="trash"
                    size="medium"
                    :skin="watchedSettings.skin"
                    :class="namespace('button__trash')"
                    :disabled="!selectRowIds.length"
                    :aria-pressed="'trash' === activeIcon"
                    @click="deleteClicked"
                    ref="settingsButton"
                    :text="initedLabels.delete"
                />
                <markets-ui-button
                    variation="icon-only"
                    icon="refresh"
                    size="medium"
                    :skin="watchedSettings.skin"
                    :class="namespace('button__refresh')"
                    :aria-pressed="'refresh' === activeIcon"
                    @click="refresh"
                    ref="settingsButton"
                    :text="initedLabels.refresh"
                />
                <span :class="namespace('create-button-wrapper')">
                    <markets-ui-button
                        :class="namespace('button__create')"
                        @click="createAlert()"
                        ref="settingsButton"
                        :skin="watchedSettings.skin"
                        :text="initedLabels.createAlert"
                    />
                </span>
            </div>
            <div
                :class="namespace('accessibility-hidden')"
                aria-live="polite"
                v-text="loadingStatus"
            ></div>
            <div :class="namespace('grid-container')" :aria-busy="showLoading">
                <markets-ui-grid
                    :skin="watchedSettings.skin"
                    :formatter="formatter"
                    :option="gridOption"
                    :columns="gridColumns"
                    :rows="gridRows"
                    :caption="gridCaption"
                    :labels="initedLabels"
                    :select-row-ids="selectRowIds"
                    @sort="onSort"
                    @icon-click="modifyAlert"
                    @page-changed="gotoPage"
                    @rows-selected-changed="selectedChanged"
                    ref="grid"
                >
                </markets-ui-grid>
            </div>

            <AlertDialog
                :options="dialogOptions"
                :labels="initedLabels"
                :dataModel="alertDataModel"
                :errorMessage="errorMessage"
                :skin="watchedSettings.skin"
                :quoteSnapshot="formatedQuoteData"
                @quote-symbol-changed="getQuoteSnapshot"
                @responsed="dialogResponse"
            ></AlertDialog>

            <alert-slider
                :labels="initedLabels"
                :visible="slider.visible"
                :dismissed="slider.dismissed"
                :dataModel="sliderData"
                :formatter="formatter"
                :skin="watchedSettings.skin"
                :showLoading="showSliderLoading"
                ref="alertSlider"
                @slider-scrolled="getSliderAlerts"
                @toggle-settings="toggleSettings"
                @hide="hideSlider"
                @manage-clicked="manageAlert"
                @alerts-acknowledged="acknowledgeAlerts"
            >
            </alert-slider>

            <alert-block
                ref="screenAlert"
                :formatter="formatter"
                :labels="initedLabels"
                :class="screenAlertCls"
                :data-model="screenAlert.latestAlert"
                :skin="watchedSettings.skin"
                @acknowledge-alerts="acknowledgeScreenAlert"
            ></alert-block>

            <audio :class="namespace('audio')"></audio>

            <markets-ui-dialog
                :skin="watchedSettings.skin"
                :visible="dialog.visible"
                :message="dialog.message"
                :resolve="dialog.resolve"
                :cancel="dialog.cancel"
                @click="dialogClicked"
                action-required
                slot="supplemental-content"
            >
                <div
                    :class="moduleClassName('mds-input__field-error-wrapper')"
                    v-if="!!dialog.errorMsg"
                >
                    <span
                        :class="[
                            moduleClassName('mds-field-error'),
                            namespace('error-message')
                        ]"
                    >
                        {{ dialog.errorMsg }}
                    </span>
                </div>
            </markets-ui-dialog>
        </div>
    </markets-ui-container>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import { getColumns } from './metadata/columns';
import { dataPoints, getDataType } from './metadata/datapoint';
import AlertDialog from './components/alert-dialog';
import AlertSlider from './components/alert-slider';
import AlertBlock from './components/alert-block';
import { getAlertByAlertId } from './metadata/alert-types';
import {
    SETTINGS,
    NOTE_TYPES,
    NOTE_SOUND,
    DISPLAY_TIME,
    ALERT_TYPES
} from './common/';
const { utils, mixins } = mwcMarketsCore;
const PENCIL = 'pencil';
export default {
    name: 'mwc-markets-alert-ui',
    mixins: [mixins.componentUI],
    components: { AlertDialog, AlertSlider, AlertBlock },
    props: {
        dataModel: {
            type: Object,
            default() {
                return {};
            }
        },
        sliderData: {
            type: Object,
            default() {
                return {};
            }
        },
        showLoading: {
            type: Boolean,
            default: false
        },
        showSliderLoading: {
            type: Boolean,
            default: false
        },
        unreadAlerts: {
            type: Object,
            default() {
                return {};
            }
        },
        quoteSnapshot: {
            type: Object,
            default() {
                return {};
            }
        },
        alertConfig: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            settingsPopoverVisible: false,
            settingsPopoverTriggeredId: null,
            settingsPopoverNavSelected: null,
            activeIcon: '',
            watchedSettings: {},
            alertSettings: {},
            alertDataModel: {},
            dropdownParent: null,
            selectRowIds: [],
            emailError: '',
            loadingStatus: '',
            slider: {
                visible: false,
                dismissed: false,
                start: 0,
                count: 4
            },
            pageData: {
                active: {
                    start: 0,
                    currentPage: 1
                },
                recurring: {
                    start: 0,
                    currentPage: 1
                },
                triggered: {
                    start: 0,
                    currentPage: 1
                }
            },
            alertDialog: {
                visible: false,
                type: ''
            },
            screenAlert: {
                visible: false,
                latestAlert: {}
            },
            dialog: {
                visible: false,
                message: '',
                errorMsg: '',
                cancel: {
                    label: ''
                },
                resolve: {
                    label: ''
                }
            },
            triggeredTimeLimit: {
                min: 1,
                max: 365
            }
        };
    },
    computed: {
        containerErrorMessage() {
            if (this._getErrorMessage(this.errorCode)) {
                return (
                    this.initedLabels[this.errorCode] ||
                    this._getErrorMessage(this.errorCode)
                );
            } else {
                return '';
            }
        },
        settingsTitle() {
            if (this.settingsPopoverNavSelected) {
                return this.settingsPopoverNavSelected.title;
            } else {
                return this.initedLabels.componentSettings;
            }
        },
        screenAlertCls() {
            const cls = [this.namespace('screen-alert')];
            if (!this.screenAlert.visible && this.screenAlert.dismissed) {
                cls.push(this.namespace('screen--dismissed'));
            } else if (!this.screenAlert.visible) {
                cls.push(this.namespace('screen-alert__hidden'));
            }
            return cls;
        },
        menuDataModel() {
            return Object.values(ALERT_TYPES).map(id => {
                return {
                    id,
                    name: this.initedLabels[`${id}Alerts`],
                    selected: this.watchedSettings.alertType === id
                };
            });
        },
        gridCaption() {
            return this.initedLabels[`${this.watchedSettings.alertType}Alerts`];
        },
        gridOption() {
            const option = {
                formatter: {}
            };
            [
                'scrollbarSize',
                'removable',
                'rowHeight',
                'autoHeight',
                'frozenColumn',
                'rowsPerPage',
                'rowsPerPageList',
                'showPageInfo',
                'showPageSelect',
                'sortAsc',
                'sortField',
                'stickyLeadBreakPoints'
            ].forEach(key => {
                if (typeof this.watchedSettings[key] !== 'undefined') {
                    option[key] = this.watchedSettings[key];
                }
            });
            // for hide table pagination then use outside one
            option.totalItems = this.dataModel.total;
            return option;
        },
        gridColumns() {
            const colns = getColumns(this.watchedSettings.alertType);
            return colns.map(col => {
                const dp = utils.find(dataPoints, item => {
                    return item.id === col;
                });
                return utils.extend(true, dp, {
                    name:
                        dp === 'checkBox'
                            ? ''
                            : this.initedLabels[dp.label || dp.id]
                });
            });
        },
        gridRows() {
            return (this.dataModel.list || []).map(item => {
                item.alertID = utils.prefixZero(item.alertID);
                const targetValue = utils.getFormatValue({
                    dataType: 'number',
                    formatter: this.formatter,
                    value: item.targetValue
                });
                const change = utils.getFormatValue({
                    dataType: 'number',
                    formatter: this.formatter,
                    value: item.change
                });
                item.alertCondition = utils.stringFormat(
                    this.initedLabels[`conditions_${item.alertID}`],
                    {
                        targetValue,
                        change: `${change}%`
                    }
                );
                item.modify = PENCIL;
                item.reactivate = PENCIL;
                return item;
            });
        },
        dialogOptions() {
            const options = {
                type: this.alertDialog.type,
                visible: this.alertDialog.visible,
                message: this.initedLabels.createDialogMessage,
                resolve: {},
                cancel: {
                    label: this.initedLabels.cancel
                }
            };
            if (this.alertDialog.type === 'create') {
                options.resolve = {
                    label: this.initedLabels.createAlert
                };
            } else if (this.alertDialog.type === 'modify') {
                options.resolve = {
                    label: this.initedLabels.update
                };
            } else if (this.alertDialog.type === 'reactivate') {
                options.resolve = {
                    label: this.initedLabels.reactivate
                };
            }
            return options;
        },
        displayTimeDataModel() {
            return Object.values(DISPLAY_TIME).map(time => {
                const regMatch = time.match(/(\d+)\s(\w+)/);
                let name = '';
                if (regMatch) {
                    name = `${regMatch[1]} ${this.initedLabels[regMatch[2]]}`;
                } else {
                    name = this.initedLabels[time];
                }
                return {
                    id: time,
                    name: name,
                    text: name,
                    selected: time === this.alertSettings.displayTime
                };
            });
        },
        showEmail() {
            return (
                !this.settingsPopoverNavSelected &&
                utils.inArray(
                    this.watchedSettings.settingsItem,
                    SETTINGS.EMAIL
                ) > -1
            );
        },
        showDisplayTime() {
            return (
                !this.settingsPopoverNavSelected &&
                utils.inArray(
                    this.watchedSettings.settingsItem,
                    SETTINGS.DISPLAY_TIME
                ) > -1
            );
        },
        showTriggeredTimeLimit() {
            return (
                !this.settingsPopoverNavSelected &&
                utils.inArray(
                    this.watchedSettings.settingsItem,
                    SETTINGS.TRIGGERED_TIME
                ) > -1
            );
        },
        settingsPopoverNav() {
            const nav = [];
            [SETTINGS.NOTE_TYPE, SETTINGS.NOTE_SOUND].forEach(item => {
                if (
                    utils.inArray(this.watchedSettings.settingsItem, item) > -1
                ) {
                    nav.push({
                        id: item,
                        name: this.initedLabels[this.alertSettings[item]],
                        title: this.initedLabels[item]
                    });
                }
            });
            return nav;
        },
        settingsPopoverNavSelectedList() {
            if (this.settingsPopoverNavSelected.id === SETTINGS.NOTE_TYPE) {
                return Object.values(NOTE_TYPES).map(key => {
                    return {
                        id: key,
                        name: this.initedLabels[key],
                        selected: key === this.alertSettings.notificationType
                    };
                });
            } else if (
                this.settingsPopoverNavSelected.id === SETTINGS.NOTE_SOUND
            ) {
                return Object.values(NOTE_SOUND).map(key => {
                    return {
                        id: key,
                        name: this.initedLabels[key],
                        selected: key === this.alertSettings.notificationSound
                    };
                });
            } else {
                return [];
            }
        },
        displayTime() {
            const _time = this.watchedSettings.displayTime;
            const _match =
                _time &&
                _time.match &&
                _time.match(/^(\d+)\s(second|minute|hour)/);
            let mTime = null;
            if (!_match) {
                return mTime;
            } else {
                const [, _number, _unit] = _match;
                switch (_unit) {
                    case 'second':
                        mTime = _number * 1000;
                        break;
                    case 'minute':
                        mTime = _number * 60 * 1000;
                        break;
                    case 'hour':
                        mTime = _number * 60 * 1000;
                        break;
                }
                return mTime;
            }
        },
        formatedQuoteData() {
            if (this.quoteSnapshot.quotes) {
                const quotes = {};
                const data = this.quoteSnapshot.quotes;
                for (const dp in data) {
                    quotes[dp] = utils.getFormatValue({
                        dataType: getDataType(dp),
                        formatter: this.formatter,
                        value: data[dp]
                    });
                }
                return {
                    instrument: this.quoteSnapshot.instrument,
                    quotes
                };
            }
            return this.quoteSnapshot;
        }
    },
    watch: {
        'unreadAlerts.count'(value, oldValue) {
            if (
                value &&
                value > oldValue &&
                oldValue !== undefined &&
                oldValue !== null
            ) {
                if (
                    this.watchedSettings.notificationType ===
                        NOTE_TYPES.SCREEN ||
                    this.watchedSettings.notificationType ===
                        NOTE_TYPES.SCREEN_EMAIL
                ) {
                    this.screenAlert.latestAlert = this.unreadAlerts.latestAlert;
                    this.screenAlert.visible = true;
                    window.clearTimeout(this.screenTimeout);
                    this.screenTimeoutStart = new Date();
                    if (this.displayTime) {
                        this.screenTimeout = window.setTimeout(() => {
                            this.screenAlert.visible = false;
                            this.screenAlert.dismissed = true;
                        }, this.displayTime);
                    }
                }
                if (this.watchedSettings.notificationSound === NOTE_SOUND.ON) {
                    this.audioEl.play();
                }
            } else if (!value && this.bellEl) {
                this.bellEl.classList.remove(this.namespace('count__visible'));
            }
            if (value && this.bellEl) {
                this.bellEl.setAttribute('data-count', value);
                this.bellEl.classList.add(this.namespace('count__visible'));
            }
            if (!isNaN(value)) {
                this.$emit('unread-alerts-count-changed', value);
            }
        },
        displayTime(value) {
            if (this.screenAlert.visible) {
                window.clearTimeout(this.screenTimeout);
                if (!value) {
                    return;
                }
                const _now = new Date().getTime();
                const _time =
                    value - (_now - this.screenTimeoutStart.getTime());
                if (_time < 0) {
                    this.screenAlert.visible = false;
                    this.screenAlert.dismissed = true;
                } else {
                    this.screenTimeout = window.setTimeout(() => {
                        this.screenAlert.visible = false;
                        this.screenAlert.dismissed = true;
                    }, _time);
                }
            }
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('alert');
        this.alertSettings = this.getAlertSettings(this.watchedSettings);
        this.$emit(
            'default-config-loaded',
            this.getDefaultConfig(this.alertSettings)
        );
        this.configUnwatch = this.$watch(
            () => this.alertConfig,
            value => {
                this.setAlertConfig(value);
                this.changeAlertType(
                    {
                        id: this.watchedSettings.alertType
                    },
                    'finish'
                );
                this.configUnwatch();
            }
        );
    },
    mounted() {
        this.audioEl = this.$el.querySelector(`.${this.namespace('audio')}`);
        this.audioEl.src = this.watchedSettings.soundUrl;
        this.bellEl = this.$el.querySelector(
            '.markets-ui-container-header__bell'
        );
        document.body.appendChild(this.$refs.screenAlert.$el);
    },
    methods: {
        toggleSettings(e) {
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.settingsPopoverTriggeredId = utils.computeTriggerId(e.target);
            this.$nextTick(() => {
                this.dropdownParent = document.querySelector(
                    '.markets-ui-popover'
                );
            });
        },
        getPageData() {
            return this.pageData[this.watchedSettings.alertType];
        },
        gotoPage(para) {
            const pageData = this.getPageData();
            pageData.currentPage = para.page;
            if (para.pageSize > 0) {
                this.watchedSettings.rowsPerPage = para.pageSize;
            }
            pageData.start =
                (pageData.currentPage - 1) * this.watchedSettings.rowsPerPage;
            this.$emit('page-changed', {
                start: pageData.start,
                count: this.watchedSettings.rowsPerPage,
                sortField: this.watchedSettings.sortField,
                sortAsc: this.watchedSettings.sortAsc,
                triggeredTimeLimit: this.watchedSettings.triggeredTimeLimit
            });
            this.$nextTick(() => {
                // this.activeIcon = '';
            });
        },
        manageAlert() {
            this.$emit('manage-clicked');
        },
        dialogResponse(btn, data) {
            if (btn === 'cancel') {
                this.hideAlertDialog();
                if (this.alertDialog.type === 'create') {
                    this.$emit('create-canceled');
                }
            } else if (btn === 'resolve') {
                if (
                    this.alertDialog.type === 'create' ||
                    this.alertDialog.type === 'reactivate'
                ) {
                    this.subscribeAlert(data);
                } else if (this.alertDialog.type === 'modify') {
                    this.updateAlert(data);
                }
            }
        },
        hideAlertDialog() {
            this.alertDialog.visible = false;
        },
        deleteAlertsFinished(data) {
            if (data.errorCode === '0') {
                this.dialog.visible = false;
                if (!this.gridRows.length) {
                    this.refresh();
                } else {
                    this.selectRowIds = [];
                    this.refreshLoadingStatus();
                }
            } else {
                this.dialog.errorMsg = this.initedLabels.error_delete;
            }
        },
        subscribeAlertSuccess() {
            this.hideAlertDialog();
            if (this.watchedSettings.alertType === ALERT_TYPES.ACTIVE) {
                this.$set(this.watchedSettings, 'sortField', null);
                this.$set(this.watchedSettings, 'sortAsc', null);
                this.gotoPage({ page: 1 });
                this.refreshLoadingStatus();
            } else {
                this.pageData.active.currentPage = 1;
                this.pageData.active.start = 0;
                this.changeAlertType({
                    id: ALERT_TYPES.ACTIVE
                });
            }
        },
        updateAlertSuccess() {
            this.hideAlertDialog();
            this.gotoPage({ page: this.getPageData().currentPage });
        },
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.emailError = '';
            this.alertSettings = this.getAlertSettings(this.watchedSettings);
        },
        changeDisplayTime(item) {
            this.alertSettings.displayTime = item.id;
        },
        changeSettingsNav(item) {
            this.settingsPopoverNavSelected = item;
            this.$refs.settings.enableTrapFocus();
        },
        changeSettingsNavSelected(item) {
            if (this.settingsPopoverNavSelected) {
                if (this.settingsPopoverNavSelected.id === SETTINGS.NOTE_TYPE) {
                    this.alertSettings.notificationType = item.id;
                    this.validEmail();
                    // notification type Changed
                } else if (
                    this.settingsPopoverNavSelected.id === SETTINGS.NOTE_SOUND
                ) {
                    this.alertSettings.notificationSound = item.id;
                    //notification sound changed
                }
            }
            this.settingsPopoverNavSelected = null;
        },
        changeAlertType(item, messageType) {
            if (item) {
                this.watchedSettings.alertType = item.id;
                this.$emit(
                    'alert-type-changed',
                    item.id,
                    this.watchedSettings.rowsPerPage,
                    this.pageData[item.id].start,
                    this.watchedSettings.triggeredTimeLimit
                );
                this.changeSettings(this._getStoreSettings());
                this.$set(this.watchedSettings, 'sortField', null);
                this.$set(this.watchedSettings, 'sortAsc', null);
                this.changeAlertTypeLoadingWatch = this.$watch(
                    'showLoading',
                    value => {
                        if (value) {
                            this.loadingStatus = this.initedLabels.loading;
                        } else {
                            this.changeAlertTypeLoadingWatch();
                            const activeAlertType = this.initedLabels[
                                `${this.watchedSettings.alertType}Alerts`
                            ];
                            if (messageType === 'refresh') {
                                this.loadingStatus = this.initedLabels.refreshedMsg;
                            } else if (messageType === 'finish') {
                                this.loadingStatus = this.initedLabels.dataTableLoaded;
                            } else {
                                this.loadingStatus = utils.stringFormat(
                                    this.initedLabels.changeAlertTypeMsg,
                                    {
                                        alertType: activeAlertType
                                    }
                                );
                            }
                        }
                    }
                );
            }
        },
        createAlert(security) {
            this.alertDialog.visible = true;
            this.alertDialog.type = 'create';
            if (security) {
                this.alertDataModel = {
                    instrument: security.instrument,
                    symbol: security.mstarSymbol,
                    currency: security.currency
                };
            }
        },

        modifyAlert(d) {
            const rowData = d.row;
            this.alertDialog.visible = true;
            if (this.watchedSettings.alertType === ALERT_TYPES.TRIGGERED) {
                this.alertDialog.type = 'reactivate';
            } else {
                this.alertDialog.type = 'modify';
            }
            const alertInfo = getAlertByAlertId(rowData.alertID);
            const secTargetValue = rowData.change ? rowData.targetValue : '';
            const targetValue = rowData.change
                ? rowData.change.replace('%', '')
                : rowData.targetValue;
            this.alertDataModel = {
                instrument: rowData.rtTicker,
                symbol: rowData.symbol,
                secTargetValue,
                targetValue,
                currency: rowData.currency,
                uniqueAlertID: rowData.uniqueAlertID,
                autoResetCheckbox: rowData.autoReset,
                alertValueType: rowData.change ? 'change' : 'targetValue',
                preCloseCheckbox: alertInfo.preClose,
                dataPoint: alertInfo.dataPoint,
                condition: alertInfo.condition
            };
        },
        subscribeAlert(data) {
            this.$emit('alert-created', data);
        },
        updateAlert(data) {
            this.$emit('alert-updated', data);
        },
        selectedChanged(data) {
            this.selectRowIds = data.map(item => item.id);
            this.selectedAlerts = data;
        },
        deleteClicked() {
            this.activeIcon = 'trash';
            if (this.selectedAlerts.length > 1) {
                utils.extend(true, this.dialog, {
                    message: utils.stringFormat(
                        this.initedLabels.deleteMessage,
                        {
                            count: `${this.selectedAlerts.length}`
                        }
                    ),
                    visible: true,
                    errorMsg: '',
                    cancel: {
                        label: this.initedLabels.cancel
                    },
                    resolve: {
                        label: this.initedLabels.delete_button
                    }
                });
            } else if (this.selectedAlerts.length) {
                this.deleteAlerts();
            }
        },
        dialogClicked(btn) {
            if (btn === 'cancel') {
                this.dialog.visible = false;
                this.dialog.errorMsg = '';
            } else if (btn === 'resolve') {
                this.deleteAlerts();
            }
        },
        deleteAlerts() {
            const deleteAlerts = this.selectedAlerts.map(item => {
                return item.id;
            });
            this.$emit('alerts-deleted', deleteAlerts.join(','));
        },
        refresh() {
            this.activeIcon = 'refresh';
            this.selectRowIds = [];
            this.gotoPage({ page: 1 });
            this.refreshLoadingStatus();
        },
        refreshLoadingStatus() {
            this.unwatchLoading = this.$watch('showLoading', value => {
                if (value) {
                    this.loadingStatus = this.initedLabels.loading;
                } else {
                    this.loadingStatus = this.initedLabels.refreshedMsg;
                    this.unwatchLoading();
                }
            });
        },
        acknowledgeScreenAlert(uniqueAlertID) {
            this.acknowledgeAlerts(uniqueAlertID);
            this.screenAlert.visible = false;
            this.screenAlert.dismissed = true;
        },
        acknowledgeAlerts(uniqueAlertID) {
            this.$emit('alerts-acknowledged', uniqueAlertID);
        },
        toggleSliderAlerts(e) {
            if (this.slider.visible) {
                this.slider.dismissed = true;
                this.slider.visible = false;
            } else {
                this.slider.visible = true;
                this.slider.dismissed = false;
                this.settingsPopoverVisible = false;
            }
            if (e && e.stopPropagation) {
                e.stopPropagation();
            }
        },
        getSliderAlerts({ start, count }) {
            this.$emit(
                'slider-scrolled',
                start,
                count,
                this.watchedSettings.triggeredTimeLimit
            );
        },
        hideSlider() {
            this.slider.dismissed = true;
            this.slider.visible = false;
            this.settingsPopoverVisible = false;
        },
        getQuoteSnapshot(instrument) {
            this.$emit('quote-symbol-changed', instrument);
        },
        onTimeLimitKeydown($event) {
            if ($event.keyCode === 69) {
                $event.preventDefault();
            }
        },
        onTimeLimitChange(value) {
            if (+value > 365) {
                this.alertSettings.triggeredTimeLimit = 365;
            } else if (value !== '' && value < 1) {
                this.alertSettings.triggeredTimeLimit = 1;
            } else if (isNaN(this.alertSettings.triggeredTimeLimit)) {
                this.alertSettings.triggeredTimeLimit = this.alertSettings.triggeredTimeLimit.replace(
                    /[^\d]/g,
                    ''
                );
            } else if (value === '') {
                this.alertSettings.triggeredTimeLimit = this.watchedSettings.triggeredTimeLimit;
            }
        },
        _getStoreSettings() {
            return {
                alertType: this.watchedSettings.alertType
            };
        },
        onSort(e, d) {
            d.e.stopImmediatePropagation();
            const columnItem = d.columnItem;
            const sortField = columnItem.id;
            const sortAsc = columnItem.sortAsc;
            this.$emit('sort-type-changed', { sortField, sortAsc });
            this.$set(this.watchedSettings, 'sortField', columnItem.id);
            this.$set(this.watchedSettings, 'sortAsc', sortAsc);
        },
        getAlertSettings(object) {
            const settings = {};
            if (object) {
                Object.values(SETTINGS).forEach(key => {
                    settings[key] = object[key];
                });
            }
            return settings;
        },
        setAlertConfig(config) {
            if (config) {
                config.profile = config.profile || {};
                [
                    SETTINGS.DISPLAY_TIME,
                    SETTINGS.NOTE_SOUND,
                    SETTINGS.TRIGGERED_TIME
                ].forEach(key => {
                    if (config.profile[key]) {
                        this.watchedSettings[key] = config.profile[key];
                    }
                });
                this.watchedSettings.email = config.email;
                const screenStatus = config.profile.screenStatus,
                    sendStatus = config.sendStatus;
                let noteType;
                if (screenStatus && sendStatus) {
                    noteType = NOTE_TYPES.SCREEN_EMAIL;
                } else if (!screenStatus && sendStatus) {
                    noteType = NOTE_TYPES.EMAIL;
                } else {
                    noteType = NOTE_TYPES.SCREEN; //DB desides if send the email
                }
                this.watchedSettings.notificationType = noteType;
                this.alertSettings = this.getAlertSettings(
                    this.watchedSettings
                );
            }
        },
        saveAlertConfig() {
            if (this.emailError !== '') {
                return;
            }
            let timeLimitChanged = false;
            if (
                this.alertSettings.triggeredTimeLimit !==
                this.watchedSettings.triggeredTimeLimit
            ) {
                timeLimitChanged = true;
            }
            utils.extend(
                true,
                this.watchedSettings,
                this.getAlertSettings(this.alertSettings)
            );
            const config = {
                email: this.alertSettings.email,
                profile: {}
            };
            [
                SETTINGS.DISPLAY_TIME,
                SETTINGS.NOTE_SOUND,
                SETTINGS.TRIGGERED_TIME
            ].forEach(key => {
                config.profile[key] = this.alertSettings[key];
            });
            switch (this.alertSettings.notificationType) {
                case NOTE_TYPES.SCREEN:
                    config.profile.screenStatus = true;
                    config.sendStatus = false;
                    break;
                case NOTE_TYPES.EMAIL:
                    config.profile.screenStatus = false;
                    config.sendStatus = true;
                    break;
                case NOTE_TYPES.SCREEN_EMAIL:
                    config.profile.screenStatus = true;
                    config.sendStatus = true;
                    break;
                default:
                    break;
            }
            this.$emit('config-changed', config);
            this.hideSettingsPopover();
            if (timeLimitChanged) {
                if (this.slider.visible) {
                    this.$refs.alertSlider.refresh();
                }
                if (this.watchedSettings.alertType === ALERT_TYPES.TRIGGERED) {
                    this.refresh();
                }
            }
            this.changeSettings(this._getStoreSettings());
        },
        validEmail() {
            if (
                this.alertSettings.email &&
                !/^([a-zA-Z]|[0-9]|)(\w|-|\.)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
                    this.alertSettings.email
                )
            ) {
                this.emailError = this.initedLabels.invalidEmail;
            } else if (
                this.alertSettings.email === '' &&
                /email/.test(this.alertSettings.notificationType)
            ) {
                this.emailError = this.initedLabels.emptyEmail;
            } else {
                this.emailError = '';
            }
        },
        emailClick(e) {
            e.stopPropagation();
        },
        getDefaultConfig(alertSettings) {
            const config = {
                lang: this.watchedSettings.languageId.split('-')[0],
                sendStatus: null,
                profile: {}
            };
            config.profile[SETTINGS.DISPLAY_TIME] =
                alertSettings[SETTINGS.DISPLAY_TIME];
            config.profile[SETTINGS.NOTE_SOUND] =
                alertSettings[SETTINGS.NOTE_SOUND];
            if (alertSettings[SETTINGS.NOTE_TYPE] === NOTE_TYPES.EMAIL) {
                config.sendStatus = true;
                config.profile.screenStatus = false;
            } else if (
                alertSettings[SETTINGS.NOTE_TYPE] === NOTE_TYPES.SCREEN_EMAIL
            ) {
                config.sendStatus = true;
                config.profile.screenStatus = true;
            } else {
                config.sendStatus = false;
                config.profile.screenStatus = true;
            }
            config.profile = JSON.stringify(config.profile);
            return config;
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
@import '@mds/utils-scss';
$namespace: 'mwc-markets-alert';
@keyframes screenfadeout {
    0% {
        max-height: 500px;
        opacity: 1;
    }
    80% {
        max-height: 0;
        opacity: 0;
        top: 0;
    }
    100% {
        max-height: 0;
        opacity: 0;
        z-index: 0;
        top: -500px;
    }
}

.mwc-markets-alert-settings.mds-popover--width-200px___markets {
    width: 230px;

    .markets-ui-form .mds-label___markets {
        margin-bottom: $mds-space-1-x;
    }
    .markets-settings-navigation {
        border-top: solid 1px $mds-color-neutral-80;
    }
}

.#{$namespace} {
    font-family: $mds-typography-font-family-sans-serif;
    &-screen--dismissed {
        animation: 0.5s ease-in-out forwards screenfadeout;
    }

    &-accessibility-hidden {
        @include mds-accessibly-hidden();
    }
    &-screen-alert {
        position: absolute;
        top: 0;
        right: 0;
        width: 260px;
        border: 1px solid #ccc;
        box-shadow: 0 2px 4px 0 hsla(0, 0%, 39%, 0.5);
        &__hidden {
            display: none;
        }
    }
    &-body {
        position: relative;
        .markets-ui-menus {
            box-sizing: border-box;
            height: 45px;
            padding: 5px;
        }
        .markets-ui-grid-form__checkbox {
            margin-left: 7px;
        }
    }
    &-buttons {
        box-sizing: border-box;
        height: 45px;
        padding: 5px;
        display: flex;
    }
    &-button {
        &__trash {
            margin-right: $mds-space-half-x;
        }
        &__refresh {
            margin-right: $mds-space-1-x;
        }
    }

    &-create-button-wrapper {
        padding-top: 2px;
    }

    &-settings__number-input .mds-input___markets {
        width: 80px;
    }
    &-settings {
        z-index: 1003;
        &__triggered-time {
            position: relative;
            & > span {
                position: absolute;
                top: 27px;
                left: 86px;
                @include mds-body-text-s();
            }
        }
    }
    &-pagination-container {
        display: flex;
        justify-content: center;
        padding: 16px 0;
    }
    &-select-container {
        .markets-ui-combo-box {
            padding: 0px 2px;
        }
    }

    &.markets-ui-container__fixed-height {
        .#{$namespace}-body {
            height: 100%;
        }
        .#{$namespace}-grid-container {
            height: calc(100% - 90px);
            overflow: auto;
        }
    }
}
.#{$namespace}.markets-ui-container__dark-gray {
    background: $mds-background-color-dark-gray;
    color: $mds-text-color-primary-on-dark;
}
</style>
