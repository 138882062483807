import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
const columnSets = [
    {
        id: 'active',
        columns: [
            'checkbox',
            'modify',
            'name',
            'symbol',
            'currency',
            'lastPrice',
            'tradeDate',
            'alertCondition',
            'createTime'
        ]
    },
    {
        id: 'triggered',
        columns: [
            'checkbox',
            'reactivate',
            'name',
            'symbol',
            'currency',
            'lastPrice',
            'tradeDate',
            'triggeredValue',
            'localTriggeredTime',
            'alertCondition',
            'createTime'
        ]
    },
    {
        id: 'recurring',
        columns: [
            'checkbox',
            'modify',
            'name',
            'symbol',
            'currency',
            'lastPrice',
            'tradeDate',
            'alertCondition',
            'createTime'
        ]
    }
];

export function getColumns(alertType) {
    const item = utils.find(columnSets, item => {
        return item.id === alertType;
    });
    return item.columns;
}
