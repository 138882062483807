<template>
    <markets-ui-dialog
        :class="namespace()"
        :title="options.message"
        :skin="skin"
        :cancel="options.cancel"
        :resolve="resolve"
        :visible="options.visible"
        message=""
        @click="dialogResponse"
        @dialog-dismissed="dialogDismissed"
        action-required
    >
        <markets-ui-form>
            <markets-ui-fieldset
                :error="!!errorMsg"
                :error-text="[errorMsg]"
                :skin="skin"
            >
                <mwc-markets-autocomplete
                    :mwc-id="acMwcId"
                    :width="autocompleteWidth"
                    :skin="skin"
                    size="small"
                    :classes="namespace('autocomplete')"
                />

                <markets-ui-search-field
                    :class="namespace('ac-input')"
                    :data-id="acMwcId"
                    :value="symbol"
                    :skin="skin"
                    :disabled="autocompleteDisabled"
                    @change="changSymbol"
                />
                <section :class="namespace('section')" :skin="skin">
                    <markets-ui-combo-box
                        :skin="skin"
                        :class="namespace('data-field-combox')"
                        :placeholder="labels.alertTypeMsg"
                        :dataModel="dataFields"
                        :disabled="dataFieldsDisabled"
                        @change="changDataField"
                    />
                    <span :class="namespace('section__is')">{{
                        labels['is']
                    }}</span>
                </section>
                <section
                    :class="namespace('section__quote-detail')"
                    v-if="quoteInfo.length"
                >
                    <div
                        :class="namespace('quote-detail')"
                        v-for="item in quoteInfo"
                        :key="item.id"
                        v-text="item.text"
                    ></div>
                </section>

                <markets-ui-combo-box
                    :skin="skin"
                    :class="namespace('condition-combox')"
                    :data-model="conditions"
                    :placeholder="labels.conditionMsg"
                    :disabled="conditionsDisabled"
                    :label="labels.alertCondition"
                    @change="changCondition"
                />
                <markets-ui-fieldset :horizontal="true" :skin="skin">
                    <markets-ui-input
                        @keyup="validateValue"
                        v-model="targetValue"
                        :label="targetValuePlaceHolder"
                        size="small"
                        hidden-label
                        :placeholder="targetValuePlaceHolder"
                        :disabled="targetValueDisabled"
                        :class="namespace('target-value')"
                    />
                    <markets-ui-radio-button-group
                        :skin="skin"
                        :dataModel="changeOrPriceOptions"
                        @change="radioButtonClicked"
                    >
                    </markets-ui-radio-button-group>
                </markets-ui-fieldset>
                <markets-ui-fieldset
                    :horizontal="true"
                    :skin="skin"
                    :class="namespace('last-section')"
                >
                    <markets-ui-input
                        @keyup="validateValue"
                        v-model="secTargetValue"
                        :label="labels['numberMsg']"
                        hidden-label
                        size="small"
                        :placeHolder="labels['numberMsg']"
                        :disabled="secTargetValueDisabled"
                        :class="namespace('target-value')"
                    />
                    <markets-ui-checkbox
                        :class="namespace('section__pre-close')"
                        :skin="skin"
                        :label="labels.preClose"
                        :value="preCloseCheckbox.value"
                        :checked="preCloseCheckbox.checked"
                        :disabled="preCloseDisabled"
                        @change="preCloseCheckboxClicked"
                    />
                </markets-ui-fieldset>
                <markets-ui-checkbox
                    :skin="skin"
                    :label="labels.autoReset"
                    :value="autoResetCheckbox.value"
                    :checked="autoResetCheckbox.checked"
                    :disabled="autoResetDisabled"
                    @change="autoResetCheckboxClicked"
                >
                </markets-ui-checkbox>
            </markets-ui-fieldset>
        </markets-ui-form>
    </markets-ui-dialog>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
import {
    getDataFields,
    getConditionsByDataField,
    getValueInfoByConditions,
    getPreCloseInfoByConditions,
    getTargetAlert,
    isIncreaseOf,
    isVolumeRelated
} from '../metadata/alert-types';
import { quoteDataPoints } from '../metadata/datapoint';
export default {
    name: 'mwc-markets-alert-dialog',
    props: {
        dataModel: {
            type: Object,
            default() {
                return {};
            }
        },
        quoteSnapshot: {
            type: Object,
            default() {
                return {};
            }
        },
        showLoading: {
            type: Boolean,
            default: false
        },
        skin: {
            type: String,
            default: 'default'
        },
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        labels: {
            type: Object,
            default() {
                return {};
            }
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            id: utils.guid(),
            symbol: '',
            autocompleteWidth: 300,
            instrument: '',
            dataPoint: '',
            condition: '',
            targetValue: '',
            secTargetValue: '',
            alertValueType: 'targetValue',
            currency: 'USD',
            preCloseCheckbox: {
                checked: false,
                size: 'small',
                value: 'preClose'
            },
            autoResetCheckbox: {
                checked: false,
                size: 'small',
                value: 'autoReset'
            },
            errorMsg: ''
        };
    },
    computed: {
        acMwcId() {
            return `AC-${utils.guid()}`;
        },
        resolve() {
            return utils.extend(true, {}, this.options.resolve, {
                disabled:
                    !this.alertType ||
                    (this.options.type === 'modify' &&
                        this.alertType.updatable === false)
            });
        },
        dataFields() {
            return getDataFields().map(dp => {
                const text = dp
                    .split('-')
                    .map(d => {
                        const dp = utils.find(
                            quoteDataPoints,
                            item => item.id === d
                        );
                        return this.labels[(dp && dp.label) || d];
                    })
                    .join(` ${this.labels.minus} `);
                return {
                    id: dp,
                    text,
                    name: text,
                    selected: dp === this.dataPoint
                };
            });
        },
        conditions() {
            return getConditionsByDataField(this.dataPoint).map(cd => {
                return {
                    id: cd,
                    text: this.labels[cd],
                    name: this.labels[cd],
                    selected: cd === this.condition
                };
            });
        },
        quoteInfo() {
            const quoteInfo = [];
            let symbolMatch = false;
            if (!this.dataPoint) {
                return quoteInfo;
            } else if (this.instrument === this.quoteSnapshot.instrument) {
                symbolMatch = true;
            }
            const dataPoints = this.dataPoint.split('-');
            const whl52Group = ['week52HighPrice', 'week52LowPrice'];
            const priceGroup = [
                'askPrice',
                'closePrice',
                'bidPrice',
                'sharePrice',
                'volume'
            ];
            dataPoints.forEach(dp => {
                const targetDp = utils.find(
                    quoteDataPoints,
                    item => item.id === dp
                );
                let dataPointLabel = this.labels[
                    (targetDp && targetDp.label) || dp
                ];
                if (dp === 'sharePrice') {
                    dataPointLabel = this.labels.tradedPrice;
                }
                let value;
                if (symbolMatch && this.quoteSnapshot.quotes) {
                    value = this.quoteSnapshot.quotes[dp];
                    if (dp !== 'volume') {
                        value =
                            dp === 'sharePrice'
                                ? this.quoteSnapshot.quotes.lastPrice
                                : value;
                        value = value
                            ? utils.getPriceWithCurrency(value, this.currency)
                            : this.defaultValue;
                    }
                } else {
                    value = this.defaultValue;
                }
                if (utils.inArray(whl52Group, dp) > -1) {
                    const dateDataPoint = dp.replace('Price', 'Date');
                    let date;
                    if (symbolMatch && this.quoteSnapshot.quotes) {
                        date =
                            this.quoteSnapshot.quotes[dateDataPoint] ||
                            this.defaultValue;
                    } else {
                        date = this.defaultValue;
                    }
                    quoteInfo.push({
                        id: dp,
                        text: utils.stringFormat(this.labels['52WHLQuoteMsg'], {
                            value,
                            dataPoint: dataPointLabel,
                            date
                        })
                    });
                } else if (utils.inArray(priceGroup, dp) > -1) {
                    quoteInfo.push({
                        id: dp,
                        text: utils.stringFormat(this.labels['priceQuoteMsg'], {
                            value,
                            dataPoint: dataPointLabel
                        })
                    });
                }
            });
            return quoteInfo;
        },
        dataFieldsDisabled() {
            return !this.instrument || this.options.type === 'modify';
        },
        conditionsDisabled() {
            return (
                !this.instrument ||
                !this.dataPoint ||
                this.options.type === 'modify'
            );
        },
        autocompleteDisabled() {
            return this.options.type === 'modify';
        },
        targetValuePlaceHolder() {
            if (this.dataPoint && this.condition) {
                const valueInfo = getValueInfoByConditions(
                    this.dataPoint,
                    this.condition
                );
                if (valueInfo.change && valueInfo.targetValue) {
                    return this.labels['numberOrPercentageMsg'];
                } else if (valueInfo.change) {
                    return this.labels['percentageMsg'];
                } else if (valueInfo.targetValue) {
                    return this.labels['numberMsg'];
                }
            }
            return this.labels['numberOrPercentageMsg'];
        },
        targetValueDisabled() {
            if (!this.instrument) {
                return true;
            } else if (this.dataPoint && this.condition) {
                const valueInfo = getValueInfoByConditions(
                    this.dataPoint,
                    this.condition
                );
                return !(valueInfo.targetValue || valueInfo.change);
            }
            return true;
        },

        preCloseDisabled() {
            if (
                this.options.type === 'modify' ||
                !this.instrument ||
                this.secTargetValue
            ) {
                return true;
            } else if (
                this.dataPoint &&
                this.condition &&
                this.alertValueType === 'change'
            ) {
                const preCloseInfo = getPreCloseInfoByConditions(
                    this.dataPoint,
                    this.condition
                );
                return !preCloseInfo.preClose && this.secTargetValue;
            }
            return true;
        },
        currencyDisabled() {
            return (
                this.options.type === 'modify' ||
                this.targetValueDisabled ||
                isVolumeRelated({ dataField: this.dataPoint })
            );
        },
        secTargetValueDisabled() {
            if (!this.instrument || this.preCloseCheckbox.checked) {
                return true;
            } else if (
                this.dataPoint &&
                this.condition &&
                this.alertValueType === 'change'
            ) {
                const preCloseInfo = getPreCloseInfoByConditions(
                    this.dataPoint,
                    this.condition
                );
                return !preCloseInfo.targetValue;
            }
            return true;
        },
        changeRadioDisabled() {
            if (this.options.type === 'modify') {
                return true;
            }
            const preCloseInfo = getPreCloseInfoByConditions(
                this.dataPoint,
                this.condition
            );
            return !(preCloseInfo.targetValue || preCloseInfo.preClose);
        },
        autoResetDisabled() {
            if (this.options.type === 'modify') {
                return true;
            }
            if (this.alertType) {
                return !this.alertType.autoReset;
            }
            return true;
        },
        changeOrPriceOptions() {
            let _ofLabel = `% ${this.labels['of']}`;
            if (
                this.dataPoint &&
                this.condition &&
                isIncreaseOf(this.dataPoint, this.condition)
            ) {
                _ofLabel = `% ${this.labels['increase']} ${this.labels['of']}`;
            }
            return [
                {
                    id: 'targetValue',
                    name: this.currency,
                    selected:
                        this.alertValueType === 'targetValue' &&
                        !this.targetValueDisabled,
                    disabled: this.currencyDisabled
                },
                {
                    id: 'change',
                    name: _ofLabel,
                    selected: this.alertValueType === 'change',
                    disabled: this.changeRadioDisabled
                }
            ];
        },
        alertType() {
            if (this.dataPoint && this.condition) {
                const change = this.alertValueType === 'change';
                const targetValue =
                    (change &&
                        this.validPositiveValue(this.secTargetValue) &&
                        this.validPositiveValue(change)) ||
                    (!change && this.validPositiveValue(this.targetValue));
                return getTargetAlert(
                    this.dataPoint,
                    this.condition,
                    change,
                    targetValue,
                    this.preCloseCheckbox.checked
                );
            }
            return null;
        }
    },
    watch: {
        symbol(value) {
            if (value === '') {
                this.instrument = '';
            }
        },
        'options.visible'(value) {
            if (!value && this.dataModel) {
                this.resetData();
            }
            this.$nextTick(() => {
                const customElement = this.$el.querySelector(
                    'mwc-markets-autocomplete'
                );
                this.autocomplete = customElement;
                this.registerAutcomplete();
            });
        },
        dataModel: {
            handler(data) {
                if (data) {
                    for (const key in data) {
                        if (
                            key !== 'preCloseCheckbox' &&
                            key !== 'autoResetCheckbox'
                        ) {
                            this[key] = data[key];
                        } else {
                            this[key].checked = !!data[key];
                        }
                    }
                }
            },
            deep: true
        },
        targetValueDisabled(value) {
            if (value) {
                this.targetValue = '';
            }
        },
        secTargetValueDisabled(value) {
            if (value) {
                this.secTargetValue = '';
            }
        },
        autoResetDisabled(value) {
            if (value) {
                this.autoResetCheckbox.checked = false;
            }
        },
        errorMessage(value) {
            this.errorMsg = value;
        },
        preCloseDisabled(value) {
            if (value) {
                this.preCloseCheckbox.checked = false;
            }
        },
        instrument(value) {
            if (value) {
                this.$emit('quote-symbol-changed', value);
            }
            this.errorMsg = '';
        }
    },
    created() {
        this.namespace = utils.namespace('alert-dialog');
        this.defaultValue = '--';
    },
    mounted() {
        this.$nextTick(() => {
            document.body.appendChild(this.$el);
        });
    },

    methods: {
        dialogDismissed() {
            this.$emit('responsed', 'cancel');
        },
        changSymbol(value) {
            this.symbol = value;
        },
        preCloseCheckboxClicked(item) {
            this.preCloseCheckbox.checked = item.checked;
        },
        radioButtonClicked(item) {
            this.alertValueType = item.id;
            this.secTargetValue = '';
        },
        autoResetCheckboxClicked(item) {
            this.autoResetCheckbox.checked = item.checked;
        },
        dialogResponse(btn, data) {
            if (btn === 'resolve' && this.errorMsg === '') {
                data = this.getAlertData();
                this.$emit('responsed', btn, data);
            } else if (btn === 'cancel') {
                this.$emit('responsed', btn, data);
            }
        },
        getAlertData() {
            let change, targetValue;
            if (this.alertType.change && this.alertType.targetValue) {
                change = this.targetValue;
                targetValue = this.secTargetValue;
            } else {
                targetValue = this.targetValue;
            }
            return {
                alertID: this.alertType.id,
                instrument: this.instrument,
                uniqueAlertID: this.dataModel.uniqueAlertID,
                change,
                targetValue,
                autoReset: this.autoResetCheckbox.checked
            };
        },
        resetData(keepInstrument) {
            if (!keepInstrument) {
                this.instrument = '';
                this.symbol = '';
            }
            this.dataPoint = '';
            this.condition = '';
            this.targetValue = '';
            this.secTargetValue = '';
            this.errorMsg = '';
            this.alertValueType = 'targetValue';
            this.preCloseCheckbox.checked = false;
            this.autoResetCheckbox.checked = false;
        },
        changDataField(item) {
            this.errorMsg = '';
            if (this.alertType) {
                return;
            }
            this.resetData(true);
            this.dataPoint = item && item.id;
        },
        changCondition(item) {
            this.condition = item && item.id;
            if (item === 'conditionMsg') {
                this.targetValue = '';
                this.secTargetValue = '';
            }
            this.errorMsg = '';
        },
        validateValue(e) {
            const value = e.target.value;
            if (value !== '' && !this.validPositiveValue(value)) {
                this.errorMsg = this.labels.error_numberLimit;
            } else {
                this.errorMsg = '';
            }
        },
        validPositiveValue(value) {
            return +value > 0;
        },
        registerAutcomplete() {
            if (typeof this.autocomplete.register === 'function') {
                this.autocomplete.register(this.acMwcId, res => {
                    this.symbol = res.symbol;
                    this.instrument = res.identifier;
                    this.currency = res.currency;
                });
            }
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-alert-dialog';

.#{$namespace} {
    font-size: $mds-typography-font-size-s;
    font-family: $mds-typography-font-family-sans-serif;
    &-autocomplete.mwc-markets-autocomplete {
        z-index: 900;
    }

    &-data-field-combox,
    &-ac-input,
    &-condition-combox {
        width: 250px;
    }
    .mds-dialog__title___markets {
        margin-bottom: $mds-space-2-x;
    }
    .mds-fieldset__horizontal___markets {
        -webkit-box-align: center;
        align-items: center;
    }
    &-last-section {
        padding: $mds-space-2-x 0;
    }
    &-section {
        display: flex;
        align-items: center;
        padding-top: $mds-space-2-x;
        line-height: 30px;
        &__is {
            margin: -3px auto auto $mds-space-1-and-a-half-x;
            display: block;
        }
        &__pre-close {
            margin-bottom: $mds-space-2-x;
            margin-left: $mds-space-half-x;
        }
        .markets-ui-checkbox {
            padding: $mds-space-half-x 0 0 $mds-space-1-and-a-half-x;
        }
    }
    &-auto-reset-section {
        padding-top: $mds-space-three-quarter-x;
    }
    &-target-value {
        max-width: 120px;
        font-size: $mds-typography-font-size-s;
    }
    &-error {
        padding-top: $mds-space-1-x;
    }
    &-section__quote-detail {
        color: $mds-text-color-primary;
        margin: -8px 0px 12px 5px;
        @include mds-body-text-s();
        line-height: 24px;
    }

    &-buttons-holder {
        justify-content: flex-end;
        padding-top: $mds-space-2-x;
    }

    &.markets-ui-dialog-container--dark-gray {
        &.#{$namespace}--active {
            background-color: $mds-background-color-dark-gray;
            color: $mds-text-color-primary-on-dark;
        }
        .#{$namespace}-quote-detail {
            color: $mds-text-color-primary-on-dark;
        }
    }
}
</style>
