import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
const alertTypes = [
    {
        id: '30',
        dataPoint: 'week52HighPrice',
        condition: 'greater',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '27',
        dataPoint: 'week52HighPrice',
        condition: 'reached',
        targetValue: false,
        preClose: false,
        change: false,
        autoReset: true,
        updatable: false
    },
    {
        id: '37',
        dataPoint: 'week52LowPrice',
        condition: 'less',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '28',
        dataPoint: 'week52LowPrice',
        condition: 'reached',
        targetValue: false,
        change: false,
        autoReset: true,
        updatable: false
    },
    {
        id: '57',
        dataPoint: 'askPrice-closePrice',
        condition: 'less',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '87',
        dataPoint: 'askPrice',
        condition: 'less',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '55',
        dataPoint: 'askPrice-closePrice',
        condition: 'greater',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '85',
        dataPoint: 'askPrice',
        condition: 'greater',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '67',
        dataPoint: 'askPrice',
        condition: 'less',
        targetValue: false,
        preClose: true,
        change: true,
        increase: true,
        autoReset: false,
        updatable: true
    },
    {
        id: '65',
        dataPoint: 'askPrice',
        condition: 'greater',
        targetValue: false,
        preClose: true,
        change: true,
        increase: true,
        autoReset: false,
        updatable: true
    },
    {
        id: '62',
        dataPoint: 'bidPrice-closePrice',
        condition: 'less',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '92',
        dataPoint: 'bidPrice',
        condition: 'less',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '60',
        dataPoint: 'bidPrice-closePrice',
        condition: 'greater',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '90',
        dataPoint: 'bidPrice',
        condition: 'greater',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '72',
        dataPoint: 'bidPrice',
        condition: 'less',
        targetValue: false,
        preClose: true,
        change: true,
        increase: true,
        autoReset: false,
        updatable: true
    },
    {
        id: '70',
        dataPoint: 'bidPrice',
        condition: 'greater',
        targetValue: false,
        preClose: true,
        change: true,
        increase: true,
        autoReset: false,
        updatable: true
    },
    {
        id: '21',
        dataPoint: 'volume',
        condition: 'twiceAvgVol',
        targetValue: false,
        preClose: false,
        change: false,
        autoReset: true,
        updatable: false
    },
    {
        id: '25',
        dataPoint: 'volume',
        condition: 'less',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '23',
        dataPoint: 'volume',
        condition: 'greater',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '52',
        dataPoint: 'sharePrice-closePrice',
        condition: 'less',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '50',
        dataPoint: 'sharePrice-closePrice',
        condition: 'greater',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '14',
        dataPoint: 'sharePrice',
        condition: 'less',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '12',
        dataPoint: 'sharePrice',
        condition: 'greater',
        targetValue: true,
        preClose: false,
        change: false,
        autoReset: false,
        updatable: true
    },
    {
        id: '19',
        dataPoint: 'sharePrice',
        condition: 'less',
        targetValue: true,
        preClose: false,
        change: true,
        autoReset: false,
        updatable: true
    },
    {
        id: '17',
        dataPoint: 'sharePrice',
        condition: 'greater',
        targetValue: true,
        preClose: false,
        change: true,
        autoReset: false,
        updatable: true
    },
    {
        id: '07',
        dataPoint: 'sharePrice',
        condition: 'less',
        targetValue: false,
        change: true,
        preClose: true,
        autoReset: true,
        updatable: true
    },
    {
        id: '05',
        dataPoint: 'sharePrice',
        condition: 'greater',
        targetValue: false,
        change: true,
        preClose: true,
        autoReset: true,
        updatable: true
    }
];

export function getDataFields() {
    const fields = alertTypes.map(item => {
        return item.dataPoint;
    });
    return [...new Set(fields)];
}
export function getConditions() {
    const conditions = alertTypes.map(item => {
        return item.condition;
    });
    return [...new Set(conditions)];
}
export function getConditionsByDataField(dataField) {
    let alerts = alertTypes.filter(item => {
        return item.dataPoint === dataField;
    });
    alerts = alerts.map(a => {
        return a.condition;
    });
    return [...new Set(alerts)];
}
export function isVolumeRelated({ dataField, id }) {
    if (!dataField && id) {
        const alert = getAlertByAlertId(utils.prefixZero(id));
        dataField = alert && alert.dataPoint;
    }
    return dataField === 'volume';
}
export function getValueInfoByConditions(dataField, condition) {
    const alerts = alertTypes.filter(item => {
        return item.dataPoint === dataField && item.condition === condition;
    });
    const valueInfo = {
        targetValue: false,
        change: false
    };
    for (let i = 0; i < alerts.length; i++) {
        if (alerts[i].targetValue) {
            valueInfo.targetValue = true;
        }
        if (alerts[i].change) {
            valueInfo.change = true;
        }
        if (valueInfo.change && valueInfo.targetValue) {
            break;
        }
    }
    return valueInfo;
}
export function getPreCloseInfoByConditions(dataField, condition) {
    const alerts = alertTypes.filter(item => {
        return (
            item.dataPoint === dataField &&
            item.condition === condition &&
            item.change === true
        );
    });
    const info = {
        preClose: false,
        targetValue: false
    };
    for (let i = 0; i < alerts.length; i++) {
        if (alerts[i].targetValue) {
            info.targetValue = true;
        }
        if (alerts[i].preClose) {
            info.preClose = true;
        }
        if (info.preClose && info.targetValue) {
            break;
        }
    }
    return info;
}
export function getAlertByAlertId(id) {
    return utils.find(alertTypes, item => {
        return item.id === id;
    });
}

export function isAutoResetAlert(id) {
    const alert = getAlertByAlertId(id);
    return alert.autoReset;
}

export function getTargetAlert(
    dataField,
    condition,
    change,
    targetValue,
    preClose
) {
    const target = utils.find(alertTypes, item => {
        return (
            item.dataPoint === dataField &&
            item.condition === condition &&
            !!item.change === !!change &&
            !!item.targetValue === !!targetValue &&
            !!item.preClose === !!preClose
        );
    });
    return target;
}

/** value*change, not value*(1+change) **/
export function isPureChange(id) {
    const alert = getAlertByAlertId(id);
    return !!(alert && alert.change && alert.dataPoint === 'sharePrice');
}

export function hasChange(id) {
    const alert = getAlertByAlertId(id);
    return !!(alert && alert.change);
}

export function hasValue(id) {
    const alert = getAlertByAlertId(id);
    return !!(alert && alert.targetValue);
}

export function isIncreaseOf(dataField, condition) {
    const alerts = alertTypes.filter(item => {
        return (
            item.dataPoint === dataField &&
            item.condition === condition &&
            item.increase === true
        );
    });
    return alerts.length > 0;
}
