var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-dialog",
    {
      class: _vm.namespace(),
      attrs: {
        title: _vm.options.message,
        skin: _vm.skin,
        cancel: _vm.options.cancel,
        resolve: _vm.resolve,
        visible: _vm.options.visible,
        message: "",
        "action-required": ""
      },
      on: { click: _vm.dialogResponse, "dialog-dismissed": _vm.dialogDismissed }
    },
    [
      _c(
        "markets-ui-form",
        [
          _c(
            "markets-ui-fieldset",
            {
              attrs: {
                error: !!_vm.errorMsg,
                "error-text": [_vm.errorMsg],
                skin: _vm.skin
              }
            },
            [
              _c("mwc-markets-autocomplete", {
                attrs: {
                  "mwc-id": _vm.acMwcId,
                  width: _vm.autocompleteWidth,
                  skin: _vm.skin,
                  size: "small",
                  classes: _vm.namespace("autocomplete")
                }
              }),
              _vm._v(" "),
              _c("markets-ui-search-field", {
                class: _vm.namespace("ac-input"),
                attrs: {
                  "data-id": _vm.acMwcId,
                  value: _vm.symbol,
                  skin: _vm.skin,
                  disabled: _vm.autocompleteDisabled
                },
                on: { change: _vm.changSymbol }
              }),
              _vm._v(" "),
              _c(
                "section",
                { class: _vm.namespace("section"), attrs: { skin: _vm.skin } },
                [
                  _c("markets-ui-combo-box", {
                    class: _vm.namespace("data-field-combox"),
                    attrs: {
                      skin: _vm.skin,
                      placeholder: _vm.labels.alertTypeMsg,
                      dataModel: _vm.dataFields,
                      disabled: _vm.dataFieldsDisabled
                    },
                    on: { change: _vm.changDataField }
                  }),
                  _vm._v(" "),
                  _c("span", { class: _vm.namespace("section__is") }, [
                    _vm._v(_vm._s(_vm.labels["is"]))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _vm.quoteInfo.length
                ? _c(
                    "section",
                    { class: _vm.namespace("section__quote-detail") },
                    _vm._l(_vm.quoteInfo, function(item) {
                      return _c("div", {
                        key: item.id,
                        class: _vm.namespace("quote-detail"),
                        domProps: { textContent: _vm._s(item.text) }
                      })
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("markets-ui-combo-box", {
                class: _vm.namespace("condition-combox"),
                attrs: {
                  skin: _vm.skin,
                  "data-model": _vm.conditions,
                  placeholder: _vm.labels.conditionMsg,
                  disabled: _vm.conditionsDisabled,
                  label: _vm.labels.alertCondition
                },
                on: { change: _vm.changCondition }
              }),
              _vm._v(" "),
              _c(
                "markets-ui-fieldset",
                { attrs: { horizontal: true, skin: _vm.skin } },
                [
                  _c("markets-ui-input", {
                    class: _vm.namespace("target-value"),
                    attrs: {
                      label: _vm.targetValuePlaceHolder,
                      size: "small",
                      "hidden-label": "",
                      placeholder: _vm.targetValuePlaceHolder,
                      disabled: _vm.targetValueDisabled
                    },
                    on: { keyup: _vm.validateValue },
                    model: {
                      value: _vm.targetValue,
                      callback: function($$v) {
                        _vm.targetValue = $$v
                      },
                      expression: "targetValue"
                    }
                  }),
                  _vm._v(" "),
                  _c("markets-ui-radio-button-group", {
                    attrs: {
                      skin: _vm.skin,
                      dataModel: _vm.changeOrPriceOptions
                    },
                    on: { change: _vm.radioButtonClicked }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "markets-ui-fieldset",
                {
                  class: _vm.namespace("last-section"),
                  attrs: { horizontal: true, skin: _vm.skin }
                },
                [
                  _c("markets-ui-input", {
                    class: _vm.namespace("target-value"),
                    attrs: {
                      label: _vm.labels["numberMsg"],
                      "hidden-label": "",
                      size: "small",
                      placeHolder: _vm.labels["numberMsg"],
                      disabled: _vm.secTargetValueDisabled
                    },
                    on: { keyup: _vm.validateValue },
                    model: {
                      value: _vm.secTargetValue,
                      callback: function($$v) {
                        _vm.secTargetValue = $$v
                      },
                      expression: "secTargetValue"
                    }
                  }),
                  _vm._v(" "),
                  _c("markets-ui-checkbox", {
                    class: _vm.namespace("section__pre-close"),
                    attrs: {
                      skin: _vm.skin,
                      label: _vm.labels.preClose,
                      value: _vm.preCloseCheckbox.value,
                      checked: _vm.preCloseCheckbox.checked,
                      disabled: _vm.preCloseDisabled
                    },
                    on: { change: _vm.preCloseCheckboxClicked }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("markets-ui-checkbox", {
                attrs: {
                  skin: _vm.skin,
                  label: _vm.labels.autoReset,
                  value: _vm.autoResetCheckbox.value,
                  checked: _vm.autoResetCheckbox.checked,
                  disabled: _vm.autoResetDisabled
                },
                on: { change: _vm.autoResetCheckboxClicked }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }