import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
export const dataPoints = [
    {
        id: 'name',
        align: 'left',
        width: 220,
        sortable: false
    },
    {
        id: 'symbol',
        align: 'left',
        sortable: true
    },
    {
        id: 'currency',
        width: 60,
        align: 'right',
        sortable: false
    },
    {
        id: 'lastPrice',
        label: 'currentPrice',
        align: 'right',
        width: 80,
        dataType: 'number',
        flash: 'color',
        sortable: false
    },
    {
        id: 'tradeDate',
        align: 'right',
        width: 100,
        dataType: 'date',
        sortable: false
    },
    {
        id: 'createTime',
        align: 'right',
        width: 100,
        dataType: 'date',
        sortable: true
    },
    {
        id: 'alertCondition',
        align: 'left',
        maxWidth: 600,
        width: 300,
        sortable: false
    },
    {
        id: 'localTriggeredTime',
        align: 'right',
        width: 150,
        sortable: true,
        dataType: 'dateTime'
    },
    {
        id: 'targetValue',
        align: 'right',
        width: 100,
        dataType: 'number',
        sortable: false
    },
    {
        id: 'triggeredValue',
        align: 'right',
        width: 80,
        dataType: 'number',
        sortable: false
    },
    {
        id: 'modify',
        align: 'center',
        width: 50,
        sortable: false,
        dataType: 'icon'
    },
    {
        id: 'reactivate',
        align: 'center',
        width: 60,
        sortable: false,
        dataType: 'icon'
    },
    {
        id: 'checkbox',
        align: 'center',
        label: '',
        width: 40,
        sortable: false,
        dataType: 'checkbox'
    }
];

export const quoteDataPoints = [
    {
        id: 'week52HighPrice',
        label: 'week52High',
        dataType: 'number'
    },
    {
        id: 'week52HighDate',
        dataType: 'date'
    },
    {
        id: 'week52LowPrice',
        label: 'week52Low',
        dataType: 'number'
    },
    {
        id: 'week52LowDate',
        dataType: 'date'
    },
    {
        id: 'askPrice',
        label: 'askPriceLabel',
        dataType: 'number'
    },
    {
        id: 'bidPrice',
        label: 'bidPriceLabel',
        dataType: 'number'
    },
    {
        id: 'closePrice',
        dataType: 'number'
    },
    {
        id: 'volume',
        dataType: 'int'
    },
    {
        id: 'lastPrice',
        label: 'currentPrice',
        dataType: 'number'
    }
];

export function getDataType(dataPoint) {
    const targetDp = utils.find(quoteDataPoints, dp => dataPoint === dp.id);
    if (targetDp) {
        return targetDp.dataType;
    }
    return null;
}
