var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: _vm.cls,
      attrs: { tabindex: "0" },
      on: {
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.acknowledgeAlerts(_vm.alert.uniqueAlertID)
        }
      }
    },
    [
      _c("div", { class: _vm.namespace("content") }, [
        _c("span", { class: _vm.namespace("__symbol") }, [
          _vm._v(
            _vm._s(_vm.alert.name) +
              " | " +
              _vm._s(_vm.alert.symbol) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("span", {
          class: _vm.namespace("__message"),
          domProps: { innerHTML: _vm._s(_vm.alert.message) }
        })
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.namespace("__time") }, [
        _vm._v(_vm._s(_vm.alert.triggeredTime))
      ]),
      _vm._v(" "),
      !_vm.alert.readStatus
        ? _c("div", {
            class: _vm.namespace("mark-as-read-icon"),
            attrs: {
              title: _vm.labels.markAsRead,
              "arial-label": _vm.labels.markAsRead,
              tabindex: "0",
              role: "button"
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.acknowledgeAlerts(_vm.alert.uniqueAlertID)
              },
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.acknowledgeAlerts(_vm.alert.uniqueAlertID)
              }
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }