var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c("section", { class: _vm.namespace("button-section") }, [
      _c(
        "div",
        { class: _vm.namespace("button-section__top") },
        [
          _c("markets-ui-button", {
            class: _vm.namespace("button__manage"),
            attrs: {
              variation: "primary",
              skin: _vm.skin,
              text: _vm.labels.manageAlert
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.manageAlert($event)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "span",
            { class: _vm.namespace("button-section__icons") },
            [
              _c("markets-ui-button", {
                ref: "settingsButton",
                class: _vm.namespace("button__settings"),
                attrs: {
                  variation: "icon-only",
                  icon: "gear",
                  size: "medium",
                  skin: _vm.skin,
                  text: _vm.labels.settings
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.clickSetting($event)
                  }
                }
              }),
              _vm._v(" "),
              _c("markets-ui-button", {
                ref: "removeButton",
                class: _vm.namespace("button__remove"),
                attrs: {
                  variation: "icon-only",
                  icon: "remove",
                  size: "medium",
                  skin: _vm.skin,
                  text: _vm.labels.remove
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.closeAlert($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.namespace("button-section__bottom") },
        [
          _c("markets-ui-button", {
            attrs: {
              skin: _vm.skin,
              variation: "flat",
              text: _vm.labels.markAllAsRead,
              disabled: _vm.acknowledgeAllDisabled
            },
            on: {
              click: function($event) {
                return _vm.acknowledgeAlerts("all")
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "section",
      { ref: "content", class: _vm.namespace("content-section") },
      [
        _vm.showLoading && !_vm.showBottomLoader
          ? [
              _c(
                "div",
                { class: _vm.namespace("loader-container") },
                [
                  _c("ContentLoader", {
                    attrs: {
                      dataModel: _vm.contentLoader,
                      secondaryColor: _vm.color.secondaryColor,
                      primaryColor: _vm.color.primaryColor
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        _vm._v(" "),
        !_vm.showLoading || _vm.showBottomLoader
          ? [
              _vm._l(_vm.alertData, function(alert) {
                return _c("AlertBlock", {
                  key: alert.uniqueAlertID,
                  attrs: {
                    formatter: _vm.formatter,
                    labels: _vm.labels,
                    dataModel: alert,
                    skin: _vm.skin
                  },
                  on: { "acknowledge-alerts": _vm.acknowledgeAlerts }
                })
              }),
              _vm._v(" "),
              _vm.showBottomLoader
                ? [
                    _c("ContentLoader", {
                      attrs: {
                        dataModel: _vm.bottomLoader,
                        secondaryColor: _vm.color.secondaryColor,
                        primaryColor: _vm.color.primaryColor
                      }
                    })
                  ]
                : _vm._e()
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }