<template>
    <div :class="classes">
        <section :class="namespace('button-section')">
            <div :class="namespace('button-section__top')">
                <markets-ui-button
                    variation="primary"
                    :skin="skin"
                    :class="namespace('button__manage')"
                    @click.stop="manageAlert"
                    :text="labels.manageAlert"
                />

                <span :class="namespace('button-section__icons')">
                    <markets-ui-button
                        variation="icon-only"
                        icon="gear"
                        size="medium"
                        :skin="skin"
                        :class="namespace('button__settings')"
                        @click.stop="clickSetting"
                        ref="settingsButton"
                        :text="labels.settings"
                    />
                    <markets-ui-button
                        variation="icon-only"
                        icon="remove"
                        size="medium"
                        :skin="skin"
                        :class="namespace('button__remove')"
                        @click.stop="closeAlert"
                        ref="removeButton"
                        :text="labels.remove"
                    />
                </span>
            </div>
            <div :class="namespace('button-section__bottom')">
                <markets-ui-button
                    :skin="skin"
                    variation="flat"
                    :text="labels.markAllAsRead"
                    :disabled="acknowledgeAllDisabled"
                    @click="acknowledgeAlerts('all')"
                />
            </div>
        </section>
        <section :class="namespace('content-section')" ref="content">
            <template v-if="showLoading && !showBottomLoader">
                <div :class="namespace('loader-container')">
                    <ContentLoader
                        :dataModel="contentLoader"
                        :secondaryColor="color.secondaryColor"
                        :primaryColor="color.primaryColor"
                    ></ContentLoader>
                </div>
            </template>
            <template v-if="!showLoading || showBottomLoader">
                <AlertBlock
                    v-for="alert in alertData"
                    :key="alert.uniqueAlertID"
                    :formatter="formatter"
                    :labels="labels"
                    :dataModel="alert"
                    :skin="skin"
                    @acknowledge-alerts="acknowledgeAlerts"
                >
                </AlertBlock>
                <template v-if="showBottomLoader">
                    <ContentLoader
                        :dataModel="bottomLoader"
                        :secondaryColor="color.secondaryColor"
                        :primaryColor="color.primaryColor"
                    ></ContentLoader>
                </template>
            </template>
        </section>
    </div>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import ContentLoader from './ui/content-loader';
import { setTimeout } from 'timers';
import AlertBlock from './alert-block';
const { utils, mixins } = mwcMarketsCore;
const SCROLL_EVENT = 'slider-scrolled';
export default {
    name: 'mwc-markets-alert-slider',
    mixins: [mixins.trapFocus],
    components: {
        ContentLoader,
        AlertBlock
    },
    props: {
        dataModel: {
            type: Object,
            default() {
                return {};
            }
        },
        showLoading: {
            type: Boolean,
            default: true
        },
        skin: {
            type: String,
            default: 'default'
        },
        labels: {
            type: Object,
            default() {
                return {};
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        dismissed: {
            type: Boolean,
            default: false
        },
        formatter: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            contentLoader: [],
            bottomLoader: [],
            showBottomLoader: false,
            acknowledgeAllValue: false
        };
    },
    computed: {
        classes() {
            const cls = [this.namespace()];
            if (this.visible) {
                cls.push(this.namespace('__visible'));
            } else if (this.dismissed) {
                cls.push(this.namespace('__dismissed'));
            }
            cls.push(`${this.namespace()}--${utils.getSkinSuffix(this.skin)}`);
            return cls;
        },
        alertData() {
            return this.dataModel.list;
        },
        color() {
            if (this.skin === 'dark') {
                return {
                    primaryColor: '#808080',
                    secondaryColor: '#ababab'
                };
            } else {
                return {};
            }
        },
        acknowledgeAllDisabled() {
            const index = utils.inArray(this.alertData, alert => {
                return !alert.readStatus;
            });
            return index < 0;
        }
    },
    watch: {
        showLoading(value) {
            if (!value) {
                this.showBottomLoader = false;
            }
        },
        visible(value) {
            if (value) {
                this.$nextTick(() => {
                    const loadingCount = this.getLoadingCount();
                    if (this.loadingCount !== loadingCount) {
                        this.loadingCount = loadingCount;
                        this.contentLoader = this.generateLoaders(loadingCount);
                    }
                    this.$emit(SCROLL_EVENT, {
                        start: 0,
                        count: this.loadingCount
                    });
                });
                setTimeout(() => {
                    this.bindEvents();
                    this.enableTrapFocus();
                }, 100);
            } else {
                this.removeEvents();
                this.disableTrapFocus();
            }
        }
    },
    created() {
        this.namespace = utils.namespace('alert-slider');
    },
    mounted() {
        document.body.appendChild(this.$el);
        utils.resizeObserver.observe(
            this.$refs.content,
            this._resizeObserverCallback
        );
        this.$refs.content.addEventListener('scroll', this.srcollEvent);
    },
    beforeDestroy() {
        utils.resizeObserver.unobserve(
            this.$refs.content,
            this._resizeObserverCallback
        );
    },
    methods: {
        _resizeObserverCallback() {
            if (this.visible) {
                const loadingCount = this.getLoadingCount();
                if (this.loadingCount !== loadingCount) {
                    this.loadingCount = loadingCount;
                    this.contentLoader = this.generateLoaders(loadingCount);
                    this.$emit(SCROLL_EVENT, {
                        start: 0,
                        count: this.loadingCount
                    });
                }
            }
        },
        manageAlert() {
            this.$emit('manage-clicked');
            this.$emit('hide');
        },
        refresh() {
            this.$emit(SCROLL_EVENT, {
                start: 0,
                count: this.loadingCount
            });
        },
        bindEvents() {
            document.addEventListener('click', this.validateClick);
        },
        removeEvents() {
            document.removeEventListener('click', this.validateClick);
        },
        clickSetting(e) {
            this.$emit('toggle-settings', e);
        },
        closeAlert() {
            this.$emit('hide');
        },
        acknowledgeAlerts(uniqueAlertID) {
            this.$emit('alerts-acknowledged', uniqueAlertID);
        },
        getLoadingCount() {
            return Math.ceil(this.$refs.content.offsetHeight / 100) + 2;
        },
        validateClick(e) {
            if (this.visible) {
                const elem = e.target || e.srcElement;
                const inSettings =
                    !document.body.contains(elem) ||
                    !!utils.closest(elem, 'mwc-markets-alert-settings', true);
                if (
                    inSettings ||
                    this.$el.contains(elem) ||
                    this.$el === elem
                ) {
                    return;
                }
                this.$emit('hide');
            }
        },
        srcollEvent(e) {
            const _el = e.target;
            const _remain = this.dataModel.total - this.alertData.length;
            if (
                !this.showBottomLoader &&
                _remain &&
                _el.scrollTop + _el.clientHeight >= _el.scrollHeight
            ) {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    const _count = _remain >= 2 ? 2 : _remain;
                    this.bottomLoader = this.generateLoaders(_count);
                    this.showBottomLoader = true;
                    this.$emit(SCROLL_EVENT, {
                        start: this.dataModel.list.length,
                        count: this.loadingCount
                    });
                }, 500);
            }
        },
        generateLoaders(loadingCount) {
            const contentLoader = [];
            for (let i = 0; i < loadingCount; i++) {
                contentLoader.push({
                    key: 'item_' + i
                });
            }
            return contentLoader;
        }
    }
};
</script>
<style lang="scss">
$namespace: 'mwc-markets-alert-slider';
@import '@mds/constants';
@import '@mds/typography';
@keyframes slide {
    from {
        right: -300px;
    }
    to {
        right: 0;
    }
}
@keyframes dismissed {
    from {
        right: 0;
    }
    to {
        right: -300px;
    }
}
.#{$namespace} {
    position: fixed;
    display: none;
    font-family: $mds-typography-font-family-sans-serif;
    border: solid 1px $mds-color-neutral-80;
    border-right: none;
    height: 100%;
    z-index: 100;
    background-color: $mds-background-color-white;
    &:focus {
        outline: none;
    }
    &__visible {
        display: block;
        right: 0;
        top: 0;
        height: 100%;
        width: 280px;
        animation: slide 0.5s;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    }
    &__dismissed {
        display: block;
        right: -300px;
        top: 0;
        height: 100%;
        width: 280px;
        animation: dismissed 0.5s;
    }
    &-loader-container {
        height: 100%;
        overflow: hidden;
        svg {
            height: 100px;
        }
    }
    &-button-section__top {
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }
    &-button-section__icons {
        display: flex;
        justify-content: space-between;
        width: 50px;
    }
    &-button-section__bottom {
        display: flex;
        position: relative;
        padding: 0px 0px 12px 12px;
        border-bottom: solid $mds-color-neutral-80 2px;
    }
    &-mark-all-as-read__text {
        display: inline-block;
    }
    &-content-section {
        height: calc(100% - 80px);
        overflow: auto;
    }
}
.#{$namespace}.#{$namespace}--dark-gray {
    background: $mds-background-color-dark-gray;
    color: $mds-text-color-primary-on-dark;
    border-color: $mds-color-neutral-20;
    border-width: 2px;
    .#{$namespace} {
        &-button-section__bottom {
            border-bottom-color: $mds-color-black;
        }
        &-button-section {
            background: $mds-color-neutral-20;
        }
    }
}
</style>
