<template>
    <div>
        <content-loader
            :height="height"
            :width="width"
            :speed="speed"
            :primaryColor="primaryColor"
            :secondaryColor="secondaryColor"
            v-for="item in dataModel"
            :key="item.key"
        >
            <rect x="12" y="10" rx="0" ry="0" width="210" height="12" />
            <rect x="12" y="30" rx="0" ry="0" width="200" height="10" />
            <rect x="12" y="45" rx="0" ry="0" width="180" height="8" />
            <rect x="12" y="62" rx="0" ry="0" width="110" height="14" />
        </content-loader>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';
import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
export default {
    name: 'markts-ui-content-loader',
    components: {
        ContentLoader
    },
    props: {
        dataModel: {
            type: Array,
            default() {
                return [];
            }
        },
        primaryColor: {
            type: String,
            default() {
                return '#f3f3f3';
            }
        },
        secondaryColor: {
            type: String,
            default() {
                return '#ecebeb';
            }
        }
    },
    data() {
        return {
            width: 230,
            height: 85,
            speed: 1
        };
    },

    created() {
        this.namespace = utils.namespace('content-loader');
    }
};
</script>
<style lang="scss"></style>
