var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ALERTUI", {
    ref: "UI",
    attrs: {
      skin: _vm.skin,
      dataModel: _vm.dataModel,
      sliderData: _vm.sliderData,
      showLoading: _vm.showLoading,
      showSliderLoading: _vm.showSliderLoading,
      errorCode: _vm.error.code,
      settings: _vm.settings,
      labels: _vm.initedLabels,
      formatter: _vm.formatter,
      unreadAlerts: _vm.unreadAlerts,
      quoteSnapshot: _vm.quoteSnapshot,
      alertConfig: _vm.alertConfig
    },
    on: {
      "default-config-loaded": _vm.getAlertConfig,
      "alert-type-changed": _vm.changeAlertType,
      "sort-type-changed": _vm.changeSortField,
      "config-changed": _vm.saveAlertConfig,
      "alerts-deleted": _vm.deleteAlerts,
      "alert-created": _vm.subscribeAlert,
      "alert-updated": _vm.updateAlert,
      "page-changed": _vm.changePage,
      "slider-scrolled": _vm.getSliderTriggeredAlert,
      "alerts-acknowledged": _vm.acknowledgeAlerts,
      "settings-changed": _vm.changeSettings,
      "manage-clicked": _vm.manageAlert,
      "create-canceled": _vm.cancelCreate,
      "unread-alerts-count-changed": _vm.changeUnreadAlertsCount,
      "quote-symbol-changed": _vm.getQuoteSnapshot
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }